import { SelectItem } from "primereact/selectitem";
import * as Yup from "yup";
import { AddOrEdtiWorkInstance as AddOrUpdateWorkInstance } from "../../queries/models/form-work-instance.model";
import { Form, Formik, FormikHelpers } from "formik";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { Button } from "primereact/button";
import FormikDropdown from "../../components/ui/formik/FormikDropdown";
import { useCallback, useMemo } from "react";
import { WorkInstanceTimeEntry } from "../../queries/models/work-instance-time-entry.model";
import FormikInputDuration from "../../components/ui/formik/FormikInputDuration";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { WorkInstanceState } from "../../queries/models/enums/work-instance-state.enum";
import { Tooltip } from "primereact/tooltip";

interface WorkEntryFormProps {
  entry: WorkInstanceTimeEntry | undefined;
  userId: number;
  installationOptions?: SelectItem[] | undefined;
  loading?: boolean;
  onCreateWorkInstance: (e: AddOrUpdateWorkInstance) => void;
  onUpdateWorkInstance: (e: AddOrUpdateWorkInstance) => void;
  onDeleteWorkInstance: (e: AddOrUpdateWorkInstance) => void;
}

export function WorkEntryForm({
  entry,
  userId,
  installationOptions = [],
  loading = false,
  onCreateWorkInstance,
  onUpdateWorkInstance,
  onDeleteWorkInstance,
}: WorkEntryFormProps) {
  const { t } = useTranslation();
  const hasWriteAccess = useMemo(() => {
    return authService.hasAccess(AppFeatures.WebWorkEntriesWrite);
  }, []);
  const initialValues: AddOrUpdateWorkInstance = {
    id: entry?.workInstanceId ?? undefined,
    userId: entry?.userId ?? userId,
    installationId: entry?.installationId ?? undefined,
    comment: entry?.comment ?? "",
    date: entry?.date,
    durationTicks: entry?.durationTicks ?? 0,
  };
  const validationSchema = Yup.object({
    userId: Yup.number().required(t("alert.required")),
    installationId: Yup.number().required(t("alert.required")).nonNullable(),
    comment: Yup.string().optional(),
    durationTicks: Yup.number()
      .required(t("alert.required"))
      .nonNullable()
      .min(1, "Duration ticks must be greater or equal to 1"),
  });

  const isDisabled =
    entry?.isLocked ||
    !hasWriteAccess ||
    entry?.workInstanceState === WorkInstanceState.Active;

  const onSubmit = useCallback(
    (values: AddOrUpdateWorkInstance, { resetForm }: FormikHelpers<any>) => {
      if (!hasWriteAccess) return;
      if (entry === undefined) {
        resetForm();
      }
      values.id ? onUpdateWorkInstance(values) : onCreateWorkInstance(values);
    },
    [entry, hasWriteAccess, onCreateWorkInstance, onUpdateWorkInstance]
  );

  return (
    <>
      {installationOptions && (
        <LoaderWrapper isLoading={loading}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className="flex w-full">
                  <Tooltip
                    target=".invalid-hours"
                    hideDelay={300}
                  />
                  {(entry?.workInstanceId && entry?.hasInvalidWorkHours) && (
                    <span className="align-content-center align-self-center">
                      <FontAwesomeIcon
                        data-pr-tooltip={t("common.invalidHours")}
                        icon={faExclamationTriangle}
                        className="invalid-hours"
                        color="red"
                      />
                    </span>
                  )}
                  <div
                    className="field p-1"
                    style={{ width: "calc(25%)" }}
                  >
                    <FormikDropdown
                      label={t("common.installation")}
                      name="installationId"
                      validationSchema={validationSchema}
                      options={installationOptions}
                      filter
                      disabled={isDisabled}
                    />
                  </div>
                  <div
                    className="field p-1"
                    style={{ width: "calc(25%)" }}
                  >
                    <FormikInputDuration
                      label={t("common.duration")}
                      name="durationTicks"
                      validationSchema={validationSchema}
                      isIndependent
                      disabled={isDisabled}
                    />
                  </div>
                  <div
                    className="field p-1"
                    style={{ width: "calc(50% - 110px)" }}
                  >
                    <FormikInputText
                      label={t("common.comment")}
                      name="comment"
                      validationSchema={validationSchema}
                      isIndependent
                      disabled={isDisabled}
                    />
                  </div>
                  <div
                    className="field p-1 flex h-full"
                    style={{ width: "110px" }}
                  >
                    <div className="actions">
                      <Button
                        label={t("common.save")}
                        type="submit"
                        className="green-action-button mr-1"
                        disabled={
                          isDisabled || !formik.isValid || !formik.dirty
                        }
                      ></Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </LoaderWrapper>
      )}
    </>
  );
}
