import { useCallback, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { TableHeader } from "../../components/ui/table-header";
import { User } from "../../queries/models/user.model";
import { SelectItem } from "primereact/selectitem";
import { Button } from "primereact/button";
import { useForceLoginAgainMutation } from "../../queries/users.query";
import { useToast } from "../../components/ui/toast-context-provider";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { useQueryClient } from "react-query";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import { useTranslation } from "react-i18next";

interface Props {
  items: User[];
  roleItems: SelectItem[];
  onAddNewUser: () => void;
  selectedUser: User | undefined;
  onSelectUser: (user: User) => void;
  hasWriteAccess?: boolean;
}

export function UsersTableOverview({
  items,
  roleItems,
  onAddNewUser,
  selectedUser,
  onSelectUser,
  hasWriteAccess = false,
}: Props) {
  const toast = useToast();
  const { t } = useTranslation();
  const { id: userId } = useLoggedUser() || {};
  const queryClient = useQueryClient();
  const [userToReloginId, setUserToReloginId] = useState<number | undefined>(
    undefined
  );
  const forceLoginAgainMutation = useForceLoginAgainMutation();

  const roleOptionColumnFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => {
      return (
        <MultiSelect
          options={roleItems}
          value={options.value}
          maxSelectedLabels={1}
          onChange={(e) => {
            options.filterApplyCallback(e.value, options.index);
            e.originalEvent.stopPropagation();
          }}
          placeholder={t("common.all")}
        />
      );
    },
    [roleItems, t]
  );

  const handleForceRelogin = useCallback(async () => {
    if (userToReloginId) {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("common.success"),
          });
          queryClient.invalidateQueries("users");
          setUserToReloginId(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      return forceLoginAgainMutation.mutateAsync(
        userToReloginId,
        mutateOptions
      );
    }
  }, [forceLoginAgainMutation, queryClient, t, toast, userToReloginId]);

  const modalProps: CustomModalProps = {
    isOpen: !!userToReloginId,
    header: t("common.confirmation"),
    onClose: () => setUserToReloginId(undefined),
    confirmation: true,
    centered: true,
    justified: true,
    height: "max-content",
    onConfirm: handleForceRelogin,
  };

  return (
    <div className="h-full border-round-xl">
      <CustomModal {...modalProps} />
      <DataTable
        className="h-full"
        scrollable={true}
        scrollHeight="flex"
        value={items}
        filterDisplay="row"
        selectionMode="single"
        selection={selectedUser}
        onSelectionChange={(e) => onSelectUser(e.value as User)}
        header={
          <TableHeader
            header={t("common.users")}
            showButton
            onClick={onAddNewUser}
          />
        }
      >
        <Column
          field="username"
          header={t("common.username")}
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder={t("common.filter")}
          showFilterMenu={false}
          className="w-3"
        />
        <Column
          field="contactName"
          header={t("common.fullName")}
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder={t("common.filter")}
          showFilterMenu={false}
          className="w-3"
        />
      <Column
          field="contactEmail"
          header={t("common.email")}
          style={{
            maxWidth: "200px",
            overflow: "clip",
            textOverflow: "ellipsis",
          }}
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder={t("common.filter")}
          showFilterMenu={false}
          className="w-2"
        />
        <Column
          field="roleId"
          header={t("common.role")}
          className="w-2"
          body={(x) => x.role.name}
          sortable
          filterElement={roleOptionColumnFilterTemplate}
          filter
          filterMatchMode="in"
          showFilterMenu={false}
        />
        {hasWriteAccess && (
          <Column
            header={t("common.action")}
            className="w-2"
            body={(data) => (
              <Button
                disabled={data.shouldLoginAgain || data.id === userId}
                label={t("common.logout")}
                icon="pi pi-power-off"
                onClick={() => setUserToReloginId(data.id)}
              />
            )}
          />
        )}
      </DataTable>
    </div>
  );
}
