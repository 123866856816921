import { IconName, fas } from "@fortawesome/free-solid-svg-icons";
import { SelectItem } from "primereact/selectitem";
import { Asset } from "../queries/models/asset.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const iconsAsSelectOptions: SelectItem[] = Object.values(fas)
  .map((x) => x.iconName)
  .sort()
  .filter((x, i, a) => i === 0 || x !== a[i - 1])
  .map((x) => ({
    value: x,
    label: x.replaceAll("-", " "),
    icon: x,
  }));

export function faIconItemTemplate(option: SelectItem) {
  if (!option) {
    return (
      <div className="flex align-items-center">
        <div>None</div>
      </div>
    );
  }
  return (
    <div className="flex align-items-center">
      {option.icon && (
        <FontAwesomeIcon
          icon={option.icon as IconName}
          className="mr-2"
          size="2x"
        />
      )}
      <div>{option.label}</div>
    </div>
  );
}

export function selectAssetIcon(asset: Asset | undefined) {
  return (asset?.tags?.flatMap((x) => x.icon).filter((x) => x)[0] ??
    undefined) as IconName | undefined;
}
