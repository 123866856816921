import { OverviewSectionComponentProps } from "../OverviewSectionComponent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OrderSynchronizationStatus } from "../../../queries/models/enums/order-synchronization-status.enum";
import { UserMonthlyOrdersOverviewItem } from "../../../queries/models/user-monthly-orders-overview.model";
import { Calendar } from "primereact/calendar";

export function MobileOverviewSectionComponent({
  items,
  date,
  selectedItem,
  onDateChange,
  onSelectItem,
}: OverviewSectionComponentProps) {
  return (
    <>
      <Calendar
        view="month"
        className="w-full p-1"
        dateFormat="MM/yy"
        onChange={(e) => onDateChange(new Date(e.value as string))}
        value={date}
      />
      <DataTable
        className="mt-1"
        selectionMode="single"
        scrollable={true}
        selection={selectedItem}
        onSelectionChange={(e) =>
          onSelectItem(e.value as UserMonthlyOrdersOverviewItem)
        }
        style={{ maxHeight: "40vh" }}
        scrollHeight="flex"
        value={items}
      >
        <Column
          field="username"
          header="Username"
          body={(x) => `${x.username} (${x.ordersCount})`}
        />
        <Column
          field="SynchronizationStatus"
          header="Status"
          body={(x) => OrderSynchronizationStatus[x.synchronizationStatus]}
        />
      </DataTable>
    </>
  );
}
