import { useEffect, useState } from "react";
import { useReportSummaryQuery } from "../../queries/reporting.query";
import { ReportSummaryItem } from "../../queries/models/report-summary-witem.model";
import { nextSunday, previousMonday } from "date-fns";
import { Calendar } from "primereact/calendar";
import { TimeSpan } from "../../utils/timespan";
import Enumerable from "linq";
import { SummaryOverviewComponent } from "./SummaryOverviewComponent";
import { SummaryGroupedTableComponent } from "./SummaryGrouppedTableComponent";
import { TableFields } from "../../queries/models/table-fields.model";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { ReportSummaryGroupItem } from "../../queries/models/report-summary-group-item.model";
import { WorkInstanceType } from "../../queries/models/enums/work-instance-type.enum";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

export function SummaryPage() {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<Date[]>([
    previousMonday(new Date()),
    nextSunday(new Date()),
  ]);
  const [items, setItems] = useState<ReportSummaryItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<ReportSummaryItem[]>();
  const [visibleItems, setVisibleItems] = useState<ReportSummaryItem[]>();
  const [usersSummary, setUsersSummary] = useState<ReportSummaryGroupItem[]>();
  const [installationsSummary, setInstallationsSummary] =
    useState<ReportSummaryGroupItem[]>();
  const [users, setUsers] = useState<string[]>();
  const [installations, setInstallations] = useState<string[]>();

  const overviewQuery = useReportSummaryQuery(dateRange[0], dateRange[1]);

  const usersSummaryFields: TableFields[] = [
    { name: "username", header: "Username" },
    { name: "workDurationRegularTicks", header: "Regular work" },
    { name: "workDurationConceptualTicks", header: "Conceptual work" },
    { name: "workDurationTicks", header: "Work time" },
  ];

  const installationSummaryFields: TableFields[] = [
    { name: "installationName", header: "Installation" },
    { name: "workDurationRegularTicks", header: "Regular work" },
    { name: "workDurationConceptualTicks", header: "Conceptual work" },
    { name: "workDurationTicks", header: "Work time" },
  ];

  useEffect(() => {
    if (overviewQuery.data) {
      let tempItems = overviewQuery.data;
      tempItems.forEach((x) => {
        x.dateOnly = new Date(x.date.toDateString());
      });
      setItems(overviewQuery.data);
      setVisibleItems(overviewQuery.data);

      setUsers(Array.from(new Set(items.map((x) => x.username))));

      setInstallations(
        Array.from(new Set(items.map((x) => x.installationName)))
      );
    }
  }, [overviewQuery.data, items]);

  useEffect(() => {
    let visibleItemsList = Enumerable.from(visibleItems ?? []);
    let visibleSelectedItems = Enumerable.from(selectedItems ?? []).where((x) =>
      visibleItemsList.contains(x!)
    );
    let usersGroups = visibleSelectedItems
      .groupBy((x) => x.userId)
      .select((x) => x.getSource());
    let installationsGroups = visibleSelectedItems
      .groupBy((x) => x.installationId)
      .select((x) => x.getSource());
    setUsersSummary(
      usersGroups
        .select((x) => {
          return {
            installationAddress: x[0].installationAddress,
            workInstanceId: x[0].workInstanceId,
            username: x[0].username,
            userId: x[0].userId,
            installationId: x[0].installationId,
            installationName: x[0].installationName,
            workDurationTicks: Enumerable.from(x).sum(
              (y) => y!.workDurationTicks
            ),
            totalCostInEur: Enumerable.from(x).sum((y) => y!.totalCostInEur),
            workDurationConceptualTicks: Enumerable.from(
              x.filter(
                (y) => y.workInstanceType === WorkInstanceType.Conceptual
              )
            ).sum((y) => y!.workDurationTicks ?? 0),
            workDurationRegularTicks: Enumerable.from(
              x.filter((y) => y.workInstanceType === WorkInstanceType.Regular)
            ).sum((y) => y!.workDurationTicks ?? 0),
          } as ReportSummaryGroupItem;
        })
        .toArray()
    );
    setInstallationsSummary(
      installationsGroups
        .select((x) => {
          return {
            installationAddress: x[0].installationAddress,
            workInstanceId: x[0].workInstanceId,
            username: x[0].username,
            userId: x[0].userId,
            installationId: x[0].installationId,
            installationName: x[0].installationName,
            workDurationTicks: Enumerable.from(x).sum(
              (y) => y!.workDurationTicks
            ),
            totalCostInEur: Enumerable.from(x).sum((y) => y!.totalCostInEur),
            workDurationConceptualTicks: Enumerable.from(
              x.filter(
                (y) => y.workInstanceType === WorkInstanceType.Conceptual
              )
            ).sum((y) => y!.workDurationTicks ?? 0),
            workDurationRegularTicks: Enumerable.from(
              x.filter((y) => y.workInstanceType === WorkInstanceType.Regular)
            ).sum((y) => y!.workDurationTicks ?? 0),
          } as ReportSummaryGroupItem;
        })
        .toArray()
    );
  }, [selectedItems, visibleItems]);

  function onDateReset() {
    setDateRange([previousMonday(new Date()), nextSunday(new Date())]);
  }

  return (
    <div className="flex p-1 h-full gap-2 overflow-hidden">
      <div className="w-8 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <div className="flex flex-column h-full">
            <div className="flex flex-column p-2 h-5rem">
              <label className="mx-2 font-medium">
                {t("common.dateRange")}
              </label>
              <Calendar
                value={dateRange}
                onClearButtonClick={onDateReset}
                onChange={(e) => {
                  setDateRange((e.value ?? []) as Date[]);
                  setSelectedItems([]);
                }}
                selectionMode="range"
                className="w-4"
                readOnlyInput
                locale="en"
                showButtonBar={true}
                dateFormat="dd/mm/yy"
              />
            </div>

            <div style={{ height: "calc(100% - 5rem)" }}>
              <LoaderWrapper isLoading={overviewQuery.isLoading}>
                <SummaryOverviewComponent
                  installations={installations}
                  users={users}
                  selectedItems={selectedItems}
                  items={items}
                  onSetSelectedItems={setSelectedItems}
                  onSetVisibleItems={setVisibleItems}
                />
              </LoaderWrapper>
            </div>
          </div>
        </RoundedShadowContainer>
      </div>
      <div className="w-4 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <div className="h-full">
            <div
              style={{ height: "80px" }}
              className="formgrid grid p-2"
            >
              <div className="field col-6 h-full">
                <span className="flex flex-column h-full">
                  <label className="mx-2 text-3xl flex align-items-center h-full">
                    {t("common.summary")}
                  </label>
                </span>
              </div>
            </div>
            <div className="h-full flex flex-column">
              <div style={{ height: "calc((100vh - 10rem) / 2)" }}>
                <SummaryGroupedTableComponent
                  fields={usersSummaryFields}
                  items={usersSummary}
                  header={"Users summary"}
                  footer={TimeSpan.fromTicks(
                    Enumerable.from(usersSummary ?? []).sum(
                      (x) => x!.workDurationTicks
                    )
                  ).toHhMm()}
                />
              </div>
              <div style={{ height: "calc((100vh - 10rem) / 2)" }}>
                <SummaryGroupedTableComponent
                  fields={installationSummaryFields}
                  items={installationsSummary}
                  header={t("common.installationsSummary")}
                  footer={TimeSpan.fromTicks(
                    Enumerable.from(installationsSummary ?? []).sum(
                      (x) => x!.workDurationTicks
                    )
                  ).toHhMm()}
                />
              </div>
            </div>
          </div>
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
