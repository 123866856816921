import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TimeSpan } from "../../utils/timespan";
import { TableHeader } from "../../components/ui/table-header";
import { useCallback } from "react";
import { TableFields } from "../../queries/models/table-fields.model";
import { ReportSummaryGroupItem } from "../../queries/models/report-summary-group-item.model";

interface SummaryGroupedTableComponentProps {
  fields: TableFields[];
  items: ReportSummaryGroupItem[] | undefined;
  header: string;
  footer: string;
  scrollHeight?: string;
}

export function SummaryGroupedTableComponent({
  fields,
  items,
  header,
  footer,
  scrollHeight = undefined,
}: SummaryGroupedTableComponentProps) {
  const customFooter = useCallback(
    () => (
      <div className="flex justify-content-end align-items-center">
        <span className="text-xl text-900 font-bold mr-2">{footer}</span>
      </div>
    ),
    [footer]
  );

  return (
    <div className="h-full">
      <DataTable
        resizableColumns={false}
        scrollable={true}
        scrollHeight={scrollHeight ?? "calc((100vh - 25rem) / 2)"}
        value={items}
        dataKey="workInstanceId"
        header={
          <TableHeader
            header={header}
            showButton={false}
          />
        }
        footer={customFooter}
      >
        {fields.map((field, index) =>
          !field.name.includes("Ticks") ? (
            <Column
              key={index}
              field={field.name}
              header={field.header}
              sortable
              filterMatchMode="contains"
              showFilterMenu={false}
            />
          ) : (
            <Column
              key={index}
              field={field.name}
              header={field.header}
              sortable
              filterMatchMode="contains"
              showFilterMenu={false}
              body={(x) => TimeSpan.fromTicks(x[field.name]).toHhMm()}
            />
          )
        )}
      </DataTable>
    </div>
  );
}
