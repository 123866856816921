import { useCallback } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useWindowSize } from "../../hooks/use-window-size";
import { useToast } from "../../components/ui/toast-context-provider";
import { Company } from "../../queries/models/company-model";
import { CreateOrUpdateCompanyRequest } from "../../queries/models/create-or-upodate-company-request";
import FormikCalendar from "../../components/ui/formik/FormikCalendar";
import { Divider } from "primereact/divider";
import FormikInputPassword from "../../components/ui/formik/formikInputPassword";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import { t } from "i18next";

export interface CompanyFormProps {
  user: Company;
  isSubmitting?: boolean;
  onSave: (form: CreateOrUpdateCompanyRequest) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
  hasWriteAccess?: boolean;
}

export function CompanyForm({
  user: company,
  onSave,
  onCancel,
  onDelete,
  hasWriteAccess = false,
}: CompanyFormProps) {
  const { lg } = useWindowSize() || {};
  const toast = useToast();

  const initialValues: CreateOrUpdateCompanyRequest = {
    id: company.id,
    name: company.name ?? "",
    dateOfExpire: company.dateOfExpire ?? undefined,
    odooAccessConfig_OdooDbName: company.odooAccessConfig_OdooDbName ?? "",
    odooAccessConfig_OdooPassword: company.odooAccessConfig_OdooPassword ?? "",
    odooAccessConfig_OdooUrl: company.odooAccessConfig_OdooUrl ?? "",
    odooAccessConfig_OdooUsername: company.odooAccessConfig_OdooUsername ?? "",
    odooBusinessConfig_CompanyId: company.odooBusinessConfig_CompanyId,
    odooBusinessConfig_TravelKmProductId:
      company.odooBusinessConfig_TravelKmProductId,
    odooBusinessConfig_WorkingHourProductId:
      company.odooBusinessConfig_WorkingHourProductId,
    address: company.address ?? "",
    city: company.city ?? "",
    postalCode: company.postalCode ?? "",
    email: company.email ?? "",
    vatEu: company.vatEu ?? "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .required(t("alert.required")),
    dateOfExpire: Yup.date().required(t("alert.required")),
    address: Yup.string().optional().nullable(),
    city: Yup.string().optional().nullable(),
    postalCode: Yup.string().optional().nullable(),
    email: Yup.string().email().optional().nullable(),
    vatEu: Yup.string().optional().nullable(),
    odooAccessConfig_OdooDbName: Yup.string().optional(),
    odooAccessConfig_OdooPassword: Yup.string().optional(),
    odooAccessConfig_OdooUrl: Yup.string().optional(),
    odooAccessConfig_OdooUsername: Yup.string().optional(),
    odooBusinessConfig_CompanyId: Yup.number().optional().nullable(),
    odooBusinessConfig_TravelKmProductId: Yup.number().optional().nullable(),
    odooBusinessConfig_WorkingHourProductId: Yup.number().optional().nullable(),
  });

  const onSubmit = useCallback(
    (values: CreateOrUpdateCompanyRequest) => {
      if (!hasWriteAccess) return;
      return onSave(values);
    },
    [hasWriteAccess, onSave]
  );

  return (
    <div className="h-full w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form
            autoComplete="off"
            className={classNames(
              "flex overflow-x-hidden",
              lg ? "flex-column" : " flex-column-reverse"
            )}
          >
            <div className="p-2">
              <EditContentHeader
                header={company?.id ? company.name : t("common.addNewCompany")}
                showDeleteButton={hasWriteAccess && !!company.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: t("alert.formInvalid"),
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />
            </div>
            <div className="formgrid grid p-2">
              <div className="field col-12 md:col-6">
                <FormikInputText
                  label={t("common.name")}
                  name="name"
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-6">
                <FormikCalendar
                  name="dateOfExpire"
                  label={t("common.trialEndDate")}
                  dateFormat="dd/mm/yy"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>

              <Divider />

              <div className="field col-12 md:col-6">
                <FormikInputText
                  label={t("common.odooURL")}
                  name="odooAccessConfig_OdooUrl"
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-6">
                <FormikInputText
                  label={t("common.odooDbName")}
                  name="odooAccessConfig_OdooDbName"
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-6">
                <FormikInputText
                  label={t("common.odooUsername")}
                  name="odooAccessConfig_OdooUsername"
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-6">
                <FormikInputPassword
                  label={t("common.odooPassword")}
                  name="odooAccessConfig_OdooPassword"
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                  disabled={!hasWriteAccess}
                  toggleMask
                  feedback={false}
                />
              </div>

              <div className="field col-12 md:col-4">
                <FormikInputNumber
                  label={t("common.companyID")}
                  name="odooBusinessConfig_CompanyId"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-4">
                <FormikInputNumber
                  label={t("common.workingHourProductID")}
                  name="odooBusinessConfig_WorkingHourProductId"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-4">
                <FormikInputNumber
                  label={t("common.travelKmProductID")}
                  name="odooBusinessConfig_TravelKmProductId"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>

              <Divider />

              <div className="field col-12 md:col-6">
                <FormikInputText
                  label={t("common.email")}
                  name="email"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-6">
                <FormikInputText
                  label={t("common.vatEu")}
                  name="vatEu"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-4">
                <FormikInputText
                  label={t("common.street")}
                  name="address"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-4">
                <FormikInputText
                  label={t("common.city")}
                  name="city"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>

              <div className="field col-12 md:col-4">
                <FormikInputText
                  label={t("common.postalCode")}
                  name="postalCode"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
