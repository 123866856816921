import { AnimatePresence, motion } from "framer-motion";
import { AddOrEditCompany } from "../../../queries/models/form-company.model";
import * as Yup from "yup";
import { phoneRegExp } from "../../../utils/phoneRegExp";
import { Form, Formik } from "formik";
import FormikInputText from "../formik/FormikInputText";
import FormikInputPassword from "../formik/formikInputPassword";
import { useAddCompanyMutation } from "../../../queries/users.query";
import { useCallback, useState } from "react";
import { useToast } from "../toast-context-provider";
import { CreateCompanyRequest } from "../../../queries/models/create-company-request.model";
import { btnVariants, btnStyles } from "../FramerMotion/button-states";
import { CompanyRegistered } from "./CompanyRegistered";
import ReCAPTCHA from "react-google-recaptcha";
import { on } from "events";

export interface RegisterFormProps {
  isVisible: boolean;
  setIsVisible: (e: boolean) => void;
  companyRegistered: boolean;
  setCompanyRegistered: (e: boolean) => void;
}

export function RegisterForm({
  isVisible,
  setIsVisible,
  companyRegistered,
  setCompanyRegistered,
}: RegisterFormProps) {
  const addCompanyMutation = useAddCompanyMutation();
  const [verificationToken, setVerificationToken] = useState<string>();
  const [registrationMutationRunning, setRegistrationMutationRunning] =
    useState(false);
  const toast = useToast();

  const initialValues: AddOrEditCompany = {
    phone: "",
    email: "",
    password: "",
    passwordRepeat: "",
    companyName: "",
  };

  const validationSchema = Yup.object({
    phone: Yup.string()
      .optional()
      .matches(phoneRegExp, "Phone number format invalid"),
    email: Yup.string().required("Required").email("Email format invalid"),
    password: Yup.string()
      .required("Required")
      .min(8, "Must be 8 characters or more"),
    passwordRepeat: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    companyName: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Required"),
  });

  const saveNewCompany = useCallback(
    (data: AddOrEditCompany) => {
      setRegistrationMutationRunning(true);

      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: "Registered!",
          });
          setCompanyRegistered(true);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
        onSettled: () => {
          setRegistrationMutationRunning(false);
        }
      };

      const request: CreateCompanyRequest = {
        phone: data.phone,
        email: data.email,
        password: data.password,

        companyName: data.companyName,

        recaptchaToken: verificationToken ?? "",
      };

      return addCompanyMutation.mutateAsync(request, mutateOptions);
    },
    [addCompanyMutation, setCompanyRegistered, toast, verificationToken]
  );

  const handleCaptchaChange = useCallback((response: any) => {
    setVerificationToken(response);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && !companyRegistered && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ ease: "linear", duration: 0.6 }}
          className="w-full flex h-full"
          style={{ borderRadius: "0px 20px 20px 0px" }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={saveNewCompany}
            enableReinitialize
            validateOnChange
            validateOnMount
          >
            {(formik) => (
              <Form
                autoComplete="off"
                className="grid"
                style={{ width: "calc(100% - 3rem)", color: "white" }}
              >
                <div
                  className="col-12 mb-1"
                  style={{ height: "80px" }}
                >
                  <FormikInputText
                    label="Company name"
                    name="companyName"
                    validationSchema={validationSchema}
                    autoComplete="off"
                    withIcon
                    disabled={!isVisible}
                  />
                </div>
                <div
                  className="col-6 mb-1"
                  style={{ height: "80px" }}
                >
                  <FormikInputText
                    label="Email"
                    name="email"
                    validationSchema={validationSchema}
                    autoComplete="off"
                    withIcon
                    disabled={!isVisible}
                  />
                </div>
                <div
                  className="col-6 mb-1"
                  style={{ height: "80px" }}
                >
                  <FormikInputText
                    label="Phone"
                    name="phone"
                    validationSchema={validationSchema}
                    autoComplete="off"
                    withIcon
                    disabled={!isVisible}
                  />
                </div>
                <div
                  className="col-6 mb-1"
                  style={{ height: "80px" }}
                >
                  <FormikInputPassword
                    label="Password"
                    name="password"
                    validationSchema={validationSchema}
                    autoComplete="off"
                    withIcon
                    disabled={!isVisible}
                    feedback={false}
                    toggleMask
                  />
                </div>
                <div
                  className="col-6 mb-1"
                  style={{ height: "80px" }}
                >
                  <FormikInputPassword
                    label="Repeat password"
                    name="passwordRepeat"
                    validationSchema={validationSchema}
                    autoComplete="off"
                    withIcon
                    disabled={!isVisible}
                    feedback={false}
                    toggleMask
                  />
                </div>
                <div
                  className="col-12 flex p-0 mt-3 justify-content-center"
                  style={{ height: "80px" }}
                >
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    size="normal"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <div
                  className="col-12 flex p-0 mt-3"
                  style={{ height: "80px" }}
                >
                  <div className="col-6">
                    <AnimatePresence>
                      <motion.button
                        style={btnStyles.paOutlinedButton}
                        initial="iniOutlinedButton"
                        whileHover="hovOutlinedButton"
                        variants={btnVariants}
                        onClick={() => {
                          setIsVisible(isVisible);
                        }}
                        type="button"
                      >
                        Back
                      </motion.button>
                    </AnimatePresence>
                  </div>
                  <div className="col-6">
                    <AnimatePresence>
                      <motion.button
                        style={(registrationMutationRunning || !verificationToken || !formik.isValid) ? btnStyles.paButtonDisabled : btnStyles.paButton}
                        initial="iniButton"
                        whileHover={
                          (registrationMutationRunning || !verificationToken || !formik.isValid) ? "hovDisabledButton" : "hovButton"
                        }
                        variants={btnVariants}
                        type="submit"
                        disabled={
                          registrationMutationRunning || !verificationToken || !formik.isValid
                        }
                      >
                        Sign up
                      </motion.button>
                    </AnimatePresence>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </motion.div>
      )}
      {isVisible && companyRegistered && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ ease: "linear", duration: 0.6 }}
          className="w-full flex h-full"
          style={{ borderRadius: "0px 20px 20px 0px" }}
        >
          <CompanyRegistered
            onShowLogin={() => {
              setIsVisible(isVisible);
              setCompanyRegistered(!companyRegistered);
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
