import { DataTable } from "primereact/datatable";
import {
  CalendarComponent,
  CalendarLegend,
} from "../../components/ui/calendar/CalendarComponent";
import { PlanningUser } from "../../queries/models/planning-users.model";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Column } from "primereact/column";
import {
  usePlanningUsersQuery,
  usePlanningWorkInstancesQuery,
} from "../../queries/planning.query";
import { endOfYesterday, isSameDay } from "date-fns";
import { EditPlanningComponent } from "./EditPlanningComponent";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";
import { useInstallationsOptionsQuery } from "../../queries/installations.query";
import { PlanningWorkInstance } from "../../queries/models/planning-work-instance.model";
import { TableHeader } from "../../components/ui/table-header";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { WorkInstanceStateColor } from "../../queries/models/enums/work-instance-state-color.enum";
import { CalendarEntry } from "../../components/ui/calendar/calendar-entry";
import { WorkInstanceState } from "../../queries/models/enums/work-instance-state.enum";
import Enumerable from "linq";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

export function PlanningPage() {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<PlanningUser[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectedDateWorkInstances, setSelectedDateWorkInstances] =
    useState<PlanningWorkInstance[]>();
  const usersQuery = usePlanningUsersQuery(selectedMonth);
  const usersOptionsQuery = useActiveUsersAsSelectOptionQuery();
  const installationsOptionsQuery = useInstallationsOptionsQuery();

  const selectedUserIds = useMemo(() => {
    return selectedUsers?.map((x) => x.userId);
  }, [selectedUsers]);

  const workInstancesQuery = usePlanningWorkInstancesQuery(
    selectedMonth,
    selectedUserIds
  );

  const visibleUsers = useMemo(() => {
    return usersQuery.data?.filter((x) => x.hasAnyWorkday || x.hasMobileRole);
  }, [usersQuery.data]);

  useEffect(() => {
    if (workInstancesQuery.data && selectedDates) {
      setSelectedDateWorkInstances(
        workInstancesQuery.data?.filter((x) =>
          selectedDates.some((y) => isSameDay(x.date, y))
        )
      );
    }
  }, [selectedDates, workInstancesQuery.data]);

  const getEntryColor = useCallback((entry: PlanningWorkInstance) => {
    switch (entry.state) {
      case WorkInstanceState.Pending: {
        return entry.date > endOfYesterday()
          ? WorkInstanceStateColor.PendingFuture
          : WorkInstanceStateColor.PendingPast;
      }
      case WorkInstanceState.Active: {
        return WorkInstanceStateColor.Active;
      }
      case WorkInstanceState.Finished: {
        return WorkInstanceStateColor.Finished;
      }
    }
  }, []);

  const calendarEntries = useMemo(() => {
    return (
      workInstancesQuery.data?.map((x) => {
        return {
          title: x.user?.username,
          subtitle: x.installationName,
          indicatorColor: getEntryColor(x),
          date: x.date,
          comment: x.comment,
          username: x.user?.username,
          installationId: x.installationId,
          installationName: x.installationName,
          workInstanceId: x.workInstanceId,
          hasMultiWorkday: x.hasMultiWorkday,
          user: x.user,
          workInstanceState: x.state,
          userId: x.user?.id,
          hasMissingMobileRole: !x.user?.role.appFeatures.find(
            (y) => y === AppFeatures.MobileInstallationWorkAccess
          ),
        } as CalendarEntry;
      }) ?? []
    );
  }, [getEntryColor, workInstancesQuery.data]);

  const calendarDayHeader = useCallback((entries: CalendarEntry[]) => {
    return (
      <div>{`User(${Enumerable.from(entries)
        .distinct((x) => x.userId)
        .count()}) Installations(${Enumerable.from(entries)
        .distinct((x) => x.installationId)
        .count()})`}</div>
    );
  }, []);

  const onDatesSelected = useCallback((x: Date[]) => setSelectedDates(x), []);
  const onMonthSelected = useCallback(
    (x: Date | undefined) => setSelectedMonth(x ?? new Date()),
    []
  );

  const calendarLegend: CalendarLegend[] = [
    {
      color: WorkInstanceStateColor.PendingFuture,
      description: t("common.pendingWorkPlannedInFutureFromToday"),
    },
    {
      color: WorkInstanceStateColor.PendingPast,
      description: t("common.pendingWorkPlannedInPastFromToday"),
    },
    {
      color: WorkInstanceStateColor.Active,
      description: t("common.workStarted"),
    },
    {
      color: WorkInstanceStateColor.Finished,
      description: t("common.workEnded"),
    },
  ];

  return (
    <div className="flex h-full w-full p-1 gap-2 overflow-hidden">
      <div className="w-3 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={usersQuery.isLoading}>
            <DataTable
              className="h-full"
              scrollHeight="flex"
              scrollable
              value={visibleUsers}
              dataKey="userId"
              header={
                <TableHeader
                  header={t("common.users")}
                  showButton={false}
                />
              }
              selection={selectedUsers}
              filterDisplay="row"
              onSelectionChange={(e) =>
                setSelectedUsers(e.value as PlanningUser[])
              }
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              />
              <Column
                field="username"
                header={t("common.username")}
                sortable
                showFilterMenu={false}
                filter
                filterMatchMode="contains"
                filterPlaceholder={t("common.filter")}
              />
              <Column
                field="hasMultiWorkday"
                header={t("common.multiWork")}
                sortable
                showFilterMenu={false}
                body={(x) => `${x.hasMultiWorkday ? "Multi " : ""}`}
              />
            </DataTable>
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className="w-9 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={workInstancesQuery.isLoading}>
            <CalendarComponent
              onDatesSelected={onDatesSelected}
              onMonthSelected={onMonthSelected}
              entries={calendarEntries}
              legend={calendarLegend}
              canSelectPast={false}
              dayHeader={calendarDayHeader}
              warnMissingMobile
            ></CalendarComponent>
            {selectedDates && selectedUsers.length > 0 && (
              <EditPlanningComponent
                selectedDates={selectedDates}
                installations={installationsOptionsQuery.data}
                users={usersOptionsQuery.data}
                entries={selectedDateWorkInstances}
                selectedUsers={selectedUsers}
              ></EditPlanningComponent>
            )}
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
