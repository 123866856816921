import { useCallback, useState, BaseSyntheticEvent, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { TimeSpan } from "../../utils/timespan";
import { format } from "date-fns";
import { ReportSummaryItem } from "../../queries/models/report-summary-witem.model";
import { useWindowSize } from "../../hooks/use-window-size";
import { TableHeaderWithFilter } from "../../components/ui/table-header-with-filter";
import { FilterMatchMode } from "primereact/api";
import { TableHeader } from "../../components/ui/table-header";
import { WorkInstanceType } from "../../queries/models/enums/work-instance-type.enum";
import { EnumExtensions } from "../../utils/enum-extensions";
import { SpaceBeforeCapital } from "../../utils/space-before-capital";
import { useTranslation } from "react-i18next";

interface SummaryOverviewComponentProps {
  users: string[] | undefined;
  installations: string[] | undefined;
  items: ReportSummaryItem[];
  selectedItems: ReportSummaryItem[] | undefined;
  onSetSelectedItems: (v: ReportSummaryItem[] | undefined) => void;
  onSetVisibleItems: (v: ReportSummaryItem[] | undefined) => void;
}

export function SummaryOverviewComponent({
  users,
  installations,
  items,
  selectedItems,
  onSetSelectedItems,
  onSetVisibleItems,
}: SummaryOverviewComponentProps) {
  const { t } = useTranslation();
  const { lg } = useWindowSize() || {};
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const workInstanceTypeOptions = useMemo(
    () => EnumExtensions.getLabelAndValues(WorkInstanceType),
    []
  );

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    username: { value: null, matchMode: FilterMatchMode.IN },
    installationName: { value: null, matchMode: FilterMatchMode.IN },
    dateOnly: { value: null, matchMode: FilterMatchMode.CONTAINS },
    workInstanceType: { value: null, matchMode: FilterMatchMode.IN },
  });

  const onGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      let _filters = { ...filters };

      const value = e.target.value;
      _filters.global.value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const workInstanceTypeRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        options={workInstanceTypeOptions}
        value={options.value}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          options.filterApplyCallback(e.value, options.index);
          e.originalEvent.stopPropagation();
        }}
        placeholder={t("common.any")}
        maxSelectedLabels={1}
      />
    ),
    [t, workInstanceTypeOptions]
  );

  const usernameRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => {
      return (
        <MultiSelect
          maxSelectedLabels={1}
          value={options.value}
          options={users}
          onChange={(e) => {
            options.filterCallback(e.value, options.index);
            options.filterApplyCallback(e.value, options.index);
            e.originalEvent.stopPropagation();
          }}
          placeholder={t("common.any")}
          className="p-column-filter"
          style={{ minWidth: "14rem" }}
          filter={true}
        />
      );
    },
    [t, users]
  );

  const installationRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => {
      return (
        <MultiSelect
          maxSelectedLabels={1}
          value={options.value}
          options={installations}
          onChange={(e) => {
            options.filterCallback(e.value, options.index);
            options.filterApplyCallback(e.value, options.index);
            e.originalEvent.stopPropagation();
          }}
          placeholder={t("common.any")}
          className="p-column-filter"
          style={{ minWidth: "14rem" }}
          filter={true}
        />
      );
    },
    [installations, t]
  );

  const dateRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => {
      return (
        <Calendar
          value={options.value}
          onChange={(e) => options.filterApplyCallback(e.value)}
          dateFormat="dd/mm/yy"
          placeholder={t("common.date")}
          locale="en"
          showButtonBar={true}
        />
      );
    },
    [t]
  );

  return (
    <DataTable
      className="items-table"
      scrollHeight="flex"
      scrollable={true}
      value={items}
      dataKey="workInstanceId"
      globalFilterFields={[
        "username",
        "installationName",
        "dateOnly",
        "workDurationTicks",
        "workInstanceType",
      ]}
      filters={lg ? undefined : filters}
      resizableColumns={true}
      header={
        lg ? (
          <TableHeader
            header={t("common.workInstances")}
            showButton={false}
          />
        ) : (
          <TableHeaderWithFilter
            header={t("common.workInstances")}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
          />
        )
      }
      onValueChange={(e) => onSetVisibleItems(e as ReportSummaryItem[])}
      selection={selectedItems}
      filterDisplay={lg ? "row" : undefined}
      onSelectionChange={(e) =>
        onSetSelectedItems(e.value as ReportSummaryItem[])
      }
      tableStyle={{ tableLayout: "fixed" }}
    >
      <Column
        selectionMode="multiple"
        headerStyle={{ width: "5%" }}
      />
      <Column
        field="dateOnly"
        header={t("common.date")}
        dataType="date"
        sortable
        body={(x) => format(x.date, "dd/LL/yyyy")}
        filterMatchMode="contains"
        filter
        filterElement={dateRowFilterTemplate}
        showFilterMenu={false}
        style={{
          width: "15%",
        }}
      />
      <Column
        field="username"
        header={t("common.username")}
        sortable
        showFilterMenu={false}
        filter
        filterElement={usernameRowFilterTemplate}
        filterMatchMode="in"
        hidden={!lg}
        style={{
          width: "20%",
        }}
      />
      <Column
        field="installationName"
        header={t("common.installation")}
        sortable
        showFilterMenu={false}
        filter
        filterElement={installationRowFilterTemplate}
        filterMatchMode="in"
        style={{
          width: "25%",
        }}
      />
      <Column
        header={t("common.workType")}
        field="workInstanceType"
        body={(x) =>
          SpaceBeforeCapital.transform(WorkInstanceType[x.workInstanceType])
        }
        sortable
        filter
        filterMatchMode="in"
        filterElement={workInstanceTypeRowFilterTemplate}
        hidden={!lg}
        showFilterMenu={false}
        style={{
          width: "20%",
        }}
      />
      <Column
        field="workDurationTicks"
        header={t("common.workTime")}
        sortable
        body={(x) => TimeSpan.fromTicks(x.workDurationTicks).toHhMm()}
        filterMatchMode="contains"
        showFilterMenu={false}
        hidden={!lg}
        style={{
          width: "15%",
        }}
      />
    </DataTable>
  );
}
