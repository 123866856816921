import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UserMonthlyOrdersValidation } from "../../queries/models/user-monthly-orders-validation.model";
import { UserMonthlyOrdersOverviewItem } from "../../queries/models/user-monthly-orders-overview.model";
import { useMemo } from "react";
import { ValidatedWorkInstance } from "../../queries/models/validated-work-instance.model";
import { Button } from "primereact/button";
import { OrderStatus } from "../../queries/models/enums/order-status.enum";
import { format } from "date-fns";
import Enumerable from "linq";
import { useGlobalLoader } from "../../hooks/useGlobalLoader";
import { InstallatorWorkTimeOrder } from "../../queries/models/orders/instllator-worktime-order.model";
import { InstallatorTravelOrder } from "../../queries/models/orders/instllator-travel-order.model";
import { EmptyContentBanner } from "../../components/ui/EmptyContentBanner";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useTranslation } from "react-i18next";
import { WorkDurationState } from "../../queries/models/enums/work-duration-state.enum";
import { WorkInstanceState } from "../../queries/models/enums/work-instance-state.enum";

export interface WorkSectionComponentProps {
  isValidationQueryLoading?: boolean;
  validation: UserMonthlyOrdersValidation | undefined;
  selectedItem: ValidatedWorkInstance | undefined;
  selectedOverviewItem: UserMonthlyOrdersOverviewItem | undefined;
  onSelectItem: (v: ValidatedWorkInstance | undefined) => void;
  onSendUserOrders: (v: UserMonthlyOrdersValidation) => void;
  onSendWorkInstanceOrders: (v: ValidatedWorkInstance) => void;
}

export function WorkSectionComponent({
  selectedItem: selectedInstance,
  validation,
  selectedOverviewItem,
  onSelectItem,
  onSendUserOrders,
  onSendWorkInstanceOrders,
}: WorkSectionComponentProps) {
  const { t } = useTranslation();
  const globalLoader = useGlobalLoader();
  const hasSubmitOrdersPrivilidge = useMemo(() => {
    return authService.hasAccess(AppFeatures.CanSubmitInvoiceOrders);
  }, []);

  const totalPendingOrders = useMemo(
    () =>
      Enumerable.from(validation?.workInstances ?? [])
        .selectMany<InstallatorTravelOrder | InstallatorWorkTimeOrder>(
          (x) => x.installatorTravelOrders
        )
        .concat(
          Enumerable.from(validation?.workInstances ?? []).selectMany<
            InstallatorTravelOrder | InstallatorWorkTimeOrder
          >((x) => x.installatorWorkTimeOrders)
        )
        .where((x) => x.status !== OrderStatus.Sent)
        .count(),
    [validation]
  );

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between">
      <span className="text-xl text-900 font-bold">
        {t("common.workInstances")}
      </span>
      <Button
        className="green-action-button w-10rem"
        label={`${t("common.sendAll")} (${totalPendingOrders})`}
        disabled={
          globalLoader ||
          totalPendingOrders === 0 ||
          !selectedOverviewItem?.hasOdooIdMapping ||
          !hasSubmitOrdersPrivilidge
        }
        onClick={(e) => onSendUserOrders(validation!)}
      />
    </div>
  );

  return (
    <div className="h-full">
      {validation !== undefined && (
        <div>
          <div
            style={{ height: "80px" }}
            className="flex justify-content-center align-items-center text-xl font-bold w-full"
          >
            <span>{validation?.user.username}</span>
            {!selectedOverviewItem?.hasOdooIdMapping && (
              <span
                className="text-red-500"
                style={{ whiteSpace: "pre" }}
              >
                {` (${t("common.missingOdooId")})`}
              </span>
            )}
          </div>
          <DataTable
            className="work-table"
            scrollable={true}
            scrollHeight="calc(100vh - 240px)"
            value={validation.workInstances}
            dataKey="id"
            header={header}
            selectionMode="single"
            selection={selectedInstance}
            onSelectionChange={(e) =>
              onSelectItem(e.value as ValidatedWorkInstance | undefined)
            }
          >
            <Column
              field="dateStarted"
              header={t("common.date")}
              sortable
              body={(x) => (
                <span className={x.hasInvalidWorkHours ? "text-red-600" : ""}>
                  {format(x.dateStarted, "dd/LL/yyyy")}
                </span>
              )}
              filterMatchMode="contains"
              showFilterMenu={false}
            />
            <Column
              field="installation.name"
              header={t("common.installation")}
              sortable
              filterMatchMode="contains"
              showFilterMenu={false}
            />
            <Column
              field="installatorTravelOrders"
              header={t("common.action")}
              body={(x) => {
                const pendingOrders = [
                  ...x.installatorTravelOrders.filter(
                    (e: any) => e.status !== OrderStatus.Sent
                  ),
                  ...x.installatorWorkTimeOrders.filter(
                    (e: any) => e.status !== OrderStatus.Sent
                  ),
                ].length;
                return (
                  <Button
                    className="green-action-button w-8rem"
                    disabled={
                      !hasSubmitOrdersPrivilidge ||
                      globalLoader ||
                      pendingOrders === 0 ||
                      !selectedOverviewItem?.hasOdooIdMapping
                    }
                    label={`${t("common.send")} (${pendingOrders})`}
                    onClick={(e) => onSendWorkInstanceOrders(x)}
                  />
                );
              }}
              sortable
            />
          </DataTable>
        </div>
      )}

      {validation === undefined && <EmptyContentBanner text="SELECT USER" />}
    </div>
  );
}
