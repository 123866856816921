import { useCallback, useState, BaseSyntheticEvent, useMemo } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { ForceFinishWorkRequest } from "../../queries/models/force-finish-work-request.model";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useToast } from "../../components/ui/toast-context-provider";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";

interface ForceFinishWorkFormProps {
  isSubmitting?: boolean;
  workInstanceId: number;
  onCancel: () => void;
  onSave: (request: ForceFinishWorkRequest, e: BaseSyntheticEvent) => void;
}

export function ForceFinishWorkForm({
  isSubmitting = false,
  workInstanceId,
  onCancel,
  onSave,
}: ForceFinishWorkFormProps) {
  const [saveClickEvent, setSaveClickEvent] = useState<
    BaseSyntheticEvent | undefined
  >(undefined);
  const toast = useToast();
  const initialValues: Omit<ForceFinishWorkRequest, "workInstanceId"> = {
    comment: "",
  };

  const validationSchema = Yup.object({
    comment: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Required"),
  });

  const onSubmit = useCallback(
    (values: any, formikHelpers: FormikHelpers<any>) => {
      const { resetForm } = formikHelpers;

      if (saveClickEvent) {
        onSave({ ...values, workInstanceId: workInstanceId }, saveClickEvent);
        setSaveClickEvent(undefined);
      }
    },
    [workInstanceId, onSave, saveClickEvent]
  );

  const hasForceFinishPermission = useMemo(() => {
    return authService.hasAccess(AppFeatures.CanForceEndWork);
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form>
            <div className="pb-2">
              <FormikInputTextarea
                label="Comment"
                name="comment"
                validationSchema={validationSchema}
                rows={4}
                isIndependent
              />
            </div>

            <div className="flex w-full gap-2">
              <Button
                type="reset"
                label="Cancel"
                className="yellow-button w-full"
                onClick={onCancel}
              />
              <Button
                type="submit"
                label="Save"
                loading={isSubmitting}
                className="green-action-button w-full"
                disabled={
                  !hasForceFinishPermission || !formik.dirty || !formik.isValid
                }
                onClick={(e) => {
                  setSaveClickEvent(e);
                  formik.submitForm();
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: "Form invalid",
                    });
                  }
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
