import { useCallback, useEffect, useRef, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { FloorPlanItem } from "../../../queries/models/InstallationPlans/floor-plan-item.model";
import { TableHeader } from "../../ui/table-header";
import { SpinnerLoaderWrapper } from "../../ui/SpinnerLoaderWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkSlash, faLink } from "@fortawesome/free-solid-svg-icons";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { useTranslation } from "react-i18next";
import { useAssetTypesQuery } from "../../../queries/asset-type.query";
import { TagItem } from "../../ui/tags/tag-item";
import { MultiSelect } from "primereact/multiselect";
import { DraggablePin } from "../../ui/DragDropPlan";
import {
  faIconItemTemplate,
  iconsAsSelectOptions,
  selectAssetIcon,
} from "../../../utils/fontAwesomeIcons";
import { SelectItem } from "primereact/selectitem";
import { Asset } from "../../../queries/models/asset.model";

interface Props {
  items: FloorPlanItem[] | undefined;
  loading: boolean;
  editableItem: FloorPlanItem | undefined;
  onAddNew: () => void;
  onSelectItem: (guid: string) => void;
  unselectItem: () => void;
  selectedItemsGuids: string[];
  setSelectedItemsGuids: (items: string[]) => void;
}

export function FloorPlanItemsTableOverview({
  items,
  loading,
  editableItem,
  onAddNew,
  onSelectItem,
  unselectItem,
  selectedItemsGuids,
  setSelectedItemsGuids,
}: Props) {
  const { t } = useTranslation();
  const assetTypesQuery = useAssetTypesQuery();

  const assetTypeOptions = useMemo(
    () =>
      assetTypesQuery.data
        ?.filter((x) => !x.isArchived)
        .map((x) => ({
          label: x.name,
          value: x.id,
          extraValue: x,
        })),
    [assetTypesQuery.data]
  );

  const assetTypeBodyTemplate = useCallback(
    (assetTypeId: number | undefined) => {
      const assetType = assetTypesQuery.data?.find((x) => x.id === assetTypeId);
      if (!assetType) return;

      return (
        <div className="flex flex-wrap">
          <TagItem
            tagName={assetType.name}
            textColor={assetType.textColor}
            backGroundColor={assetType.backgroundColor}
          />
        </div>
      );
    },
    [assetTypesQuery.data]
  );

  const assetPinBodyTemplate = useCallback((item: FloorPlanItem) => {
    return (
      <DraggablePin
        pin={{
          id: (item.id || item.guid) ?? 0,
          name: item.name,
          color: `#${item.color}`,
          unattached: true,
          guid: item.guid,
          asText: !item.assetId,
          icon: selectAssetIcon(item.asset),
        }}
      />
    );
  }, []);

  const assetTypeFilterElement = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        options={assetTypeOptions}
        maxSelectedLabels={1}
        showClear
        value={options.value}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          options.filterApplyCallback(e.value, options.index);
          e.originalEvent.stopPropagation();
        }}
        placeholder={t("common.any")}
        selectedItemTemplate={assetTypeBodyTemplate}
      />
    ),
    [assetTypeBodyTemplate, assetTypeOptions, t]
  );

  const assetTypeFilterFunction = useCallback(
    (val: number | undefined, filter: number[] | undefined) => {
      if (!filter || filter?.length === 0) return true;
      if (!val) return false;

      if (filter.some((x) => x === val)) return true;

      return false;
    },
    []
  );

  const isAttachedBodyRowTemplate = useCallback((item: FloorPlanItem) => {
    return (
      <FontAwesomeIcon
        icon={item.isAttached ? faLink : faLinkSlash}
        className="pr-2"
      />
    );
  }, []);

  const assetNameBodyTemplate = useCallback((item: FloorPlanItem) => {
    return <div className="p-1 text-center border-round-xl">{item.name}</div>;
  }, []);

  const attachedRowFilterElement = useCallback(
    (props: ColumnFilterElementTemplateOptions) => {
      return (
        <TriStateCheckbox
          value={props.value}
          onChange={(e) => props.filterApplyCallback(e.value)}
        />
      );
    },
    []
  );

  const filteredIconsAsSelectOptions: SelectItem[] = useMemo(() => {
    const iconsNames =
      items?.map((x) => x?.asset?.tags[0]?.icon).filter(Boolean) || [];

    return iconsAsSelectOptions.filter((x) => iconsNames.includes(x?.value));
  }, [items]);

  const assetPinRowFilterElement = useCallback(
    (options: ColumnFilterElementTemplateOptions) => {
      return (
        <MultiSelect
          options={filteredIconsAsSelectOptions}
          itemTemplate={faIconItemTemplate}
          maxSelectedLabels={1}
          value={options.value}
          onChange={(e) => {
            options.filterApplyCallback(e.value);
          }}
          filter
          placeholder={t("common.any")}
        />
      );
    },
    [filteredIconsAsSelectOptions, t]
  );

  const visibleGuids = useRef<string[]>(selectedItemsGuids);
  const selectedGuids = useRef<string[]>(selectedItemsGuids);

  useEffect(() => {
    selectedGuids.current = selectedGuids.current
      .filter(
        (x) =>
          selectedItemsGuids.includes(x) || !visibleGuids.current.includes(x)
      )
      .concat(
        selectedItemsGuids.filter((x) => !selectedGuids.current.includes(x))
      );
  }, [selectedItemsGuids]);

  const dataTableItemsSelected = useMemo(() => {
    return selectedItemsGuids
      .map((x) => items?.find((y) => y.guid === x)!)
      .filter((x) => x.isAttached)
      .filter(Boolean);
  }, [items, selectedItemsGuids]);

  return (
    <div className="h-full">
      {!loading ? (
        <DataTable
          size="small"
          scrollable
          scrollHeight="flex"
          rowClassName={(e) =>
            e.id === editableItem?.id ? "highlighted" : "white-row"
          }
          value={items}
          onRowClick={(e) => {
            onSelectItem(e.data.guid);
            if (editableItem?.guid === e.data.guid) unselectItem();
          }}
          header={
            <TableHeader
              header={t("common.planItems")}
              showButton
              onClick={onAddNew}
            />
          }
          filterDisplay="row"
          selectionMode="checkbox"
          selection={dataTableItemsSelected}
          onSelectionChange={(e) => {
            const selectedVisible = (e.value as FloorPlanItem[])
              .map((x) => x.guid!)
              .filter(Boolean);
            setSelectedItemsGuids(selectedVisible);
            e.originalEvent.stopPropagation();
          }}
          onValueChange={(e) => {
            visibleGuids.current = e.map((x) => x.guid!).filter(Boolean);
            setSelectedItemsGuids(
              selectedGuids.current.filter((x) =>
                visibleGuids.current.includes(x)
              )
            );
          }}
          isDataSelectable={(e) => e.data?.isAttached}
        >
          <Column selectionMode="multiple" />
          <Column
            field="name"
            header={t("common.name")}
            body={assetNameBodyTemplate}
            filter
            filterMatchMode="contains"
            showFilterMenu={false}
          />
          <Column
            field="isAttached"
            header={t("common.attached")}
            body={isAttachedBodyRowTemplate}
            filter
            showFilterMenu={false}
            filterMatchMode="equals"
            filterElement={attachedRowFilterElement}
          />
          <Column
            header={t("common.type")}
            field="asset.assetTypeId"
            body={(x) => assetTypeBodyTemplate(x.asset?.assetTypeId)}
            filter
            filterMatchMode="custom"
            showFilterMenu={false}
            filterElement={assetTypeFilterElement}
            filterFunction={assetTypeFilterFunction}
          />
          <Column
            header={t("common.asset")}
            field="asset"
            body={assetPinBodyTemplate}
            filter
            filterField="asset"
            filterMatchMode="custom"
            showFilterMenu={false}
            filterFunction={(value: Asset, filter: string[]) => {
              if (!filter || filter.length === 0) return true;
              if (!value) return false;
              return filter?.includes(value.tags[0]?.icon ?? "");
            }}
            filterElement={assetPinRowFilterElement}
          />
        </DataTable>
      ) : (
        <SpinnerLoaderWrapper />
      )}
    </div>
  );
}
