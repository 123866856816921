import {
  addDays,
  addMonths,
  isFuture,
  isPast,
  isSameDay,
  isToday,
  isWeekend,
  startOfMonth,
} from "date-fns";
import { CalendarDayModel } from "./calendar-day.model";
import { DateUtils } from "../../../utils/date-utils";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import { CalendarDay } from "./CalendarDay";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { useWindowSize } from "../../../hooks/use-window-size";
import { CustomModal, CustomModalProps } from "../MobileModal/custom-modal";
import { CalendarEntry } from "./calendar-entry";
import { useTranslation } from "react-i18next";
import { MenuItem } from "primereact/menuitem";

export interface CalendarBodyProps {
  width?: string;
  calendarDayStyle?: string;
  showUserOnly: boolean;
}

export interface CalendarLegend {
  color: string;
  description: string;
}

interface CalendarComponentProps {
  onDatesSelected: (v: Date[]) => void;
  defaultDatesSelected?: Date[] | Date;
  onMonthSelected: (v: Date) => void;
  defaultMonthSelected?: Date;
  entries: CalendarEntry[] | undefined;
  showButtons?: boolean;
  singleSelection?: boolean;
  bodyOptions?: CalendarBodyProps;
  legend?: CalendarLegend[];
  canSelectFuture?: boolean;
  canSelectPast?: boolean;
  warnMissingMobile?: boolean;
  groupEntriesByName?: boolean;
  dayHeader?: (entries: CalendarEntry[]) => JSX.Element;
  additionalMenuItems?: MenuItem[];
}

export function CalendarComponent({
  onDatesSelected,
  defaultDatesSelected,
  onMonthSelected,
  defaultMonthSelected,
  entries = [],
  showButtons = false,
  bodyOptions,
  legend,
  canSelectFuture = true,
  canSelectPast = true,
  singleSelection = false,
  warnMissingMobile = false,
  groupEntriesByName = false,
  dayHeader,
  additionalMenuItems,
}: CalendarComponentProps) {
  const { t } = useTranslation();
  const { sm } = useWindowSize();
  const daysInWeek = useMemo(
    () =>
      sm
        ? ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]
        : [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
    [sm]
  );
  const calendarMenu = useRef(null);
  const [showWeekend, setShowWeekend] = useState<boolean>(true);
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<Date>(
    defaultMonthSelected ?? startOfMonth(new Date())
  );
  const [selectedDays, setSelectedDays] = useState<Date[]>(
    defaultDatesSelected
      ? Array.isArray(defaultDatesSelected)
        ? defaultDatesSelected
        : [defaultDatesSelected]
      : []
  );
  const [fakedSelection, setFakedSelection] = useState<Date[]>([]);

  useEffect(() => {
    onDatesSelected(selectedDays);
    // shouldn't call on onDatesSelected change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  useEffect(() => {
    onMonthSelected(selectedMonth);
    // shouldn't call on onMonthSelected change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  const menuItems: MenuItem[] = useMemo(() => {
    var menuItems = [
      {
        label: t("common.options"),
        items: [
          {
            label: showWeekend
              ? t("common.hideWeekend")
              : t("common.showWeekend"),
            icon: "pi pi-filter",
            command: () => {
              setShowWeekend(!showWeekend);
            },
          },
        ],
      },
    ];

    if (legend)
      menuItems[0].items.push({
        label: t("common.legend"),
        icon: "pi pi-tag",
        command: () => {
          setShowLegend(!showLegend);
        },
      });

    if (additionalMenuItems) {
      (menuItems[0].items as MenuItem[]).push(...additionalMenuItems);
    }

    return menuItems;
  }, [t, showWeekend, legend, additionalMenuItems, showLegend]);

  const showWeekendForDay = useCallback(
    (day: Date): boolean => {
      return showWeekend || !isWeekend(day);
    },
    [showWeekend]
  );

  const isDayToday = useCallback((day: Date): boolean => {
    if (day !== null) {
      return isToday(day);
    }
    return false;
  }, []);

  const isSelected = useCallback(
    (day?: Date): boolean => {
      return (
        !!day &&
        (selectedDays.some((x) => isSameDay(day, x)) ? 1 : 0) +
          (fakedSelection.some((x) => isSameDay(day, x)) ? 1 : 0) ===
          1
      );
    },
    [fakedSelection, selectedDays]
  );

  const isSelectable = useCallback(
    (day?: Date): boolean => {
      return (
        !!day &&
        (canSelectFuture || !isFuture(day)) &&
        (canSelectPast || !isPast(day))
      );
    },
    [canSelectFuture, canSelectPast]
  );

  const calendarDays = useMemo(() => {
    let tempDays = [];
    const monthFirstDay = startOfMonth(selectedMonth);

    for (let i = DateUtils.getNumberOfDayInWeek(monthFirstDay)!; i >= 1; i--) {
      const firstDayInMonth = startOfMonth(selectedMonth);
      firstDayInMonth.setDate(firstDayInMonth.getDate() - i);
      tempDays.push(firstDayInMonth);
    }

    for (let i = 0; i < DateUtils.getNumberOfDaysInMonth(monthFirstDay); i++) {
      const newDate = new Date(monthFirstDay.setDate(i + 1));
      tempDays.push(newDate);
    }

    let tempCalendarDays: CalendarDayModel[] = [];
    tempDays.forEach((day) => {
      if (!showWeekendForDay(day)) return;
      tempCalendarDays.push({
        day: day,
        entries: getEntriesForDay(entries, day),
        notWorkingDay: false,
        isHoliday: false,
      });
    });
    return tempCalendarDays;
  }, [selectedMonth, showWeekendForDay, entries]);

  function getEntriesForDay(entries: CalendarEntry[], day: Date): any[] {
    return entries.filter((x) => isSameDay(x.date, day));
  }

  const onSelectionStart = useCallback(
    (day: Date) => {
      if (!canSelectFuture && isFuture(day)) return;
      if (!canSelectPast && isPast(day)) return;
      if (singleSelection) {
        setSelectedDays([day]);
        return;
      }

      setFakedSelection([day]);
    },
    [canSelectFuture, canSelectPast, singleSelection]
  );
  const onSelectionMove = useCallback(
    (day: Date) => {
      if (singleSelection) return;
      if (!canSelectFuture && isFuture(day)) return;
      if (!canSelectPast && isPast(day)) return;
      if (!fakedSelection[0]) return;
      const newOne = [fakedSelection[0]];
      const next = (x: Date) => addDays(x, fakedSelection[0] < day ? -1 : 1);
      for (let i = day; !isSameDay(i, fakedSelection[0]); i = next(i)) {
        newOne.push(i);
      }
      setFakedSelection(newOne);
    },
    [canSelectFuture, canSelectPast, fakedSelection, singleSelection]
  );
  const onSelectionEnd = useCallback(() => {
    if (singleSelection) return;
    if (!canSelectFuture && isFuture(fakedSelection[0])) return;
    if (!canSelectPast && isPast(fakedSelection[0])) return;
    if (!fakedSelection[0]) return;
    const newSelection = selectedDays
      .concat(fakedSelection)
      .filter((x) => isSelected(x))
      .sort((a, b) => +a - +b);
    setSelectedDays(newSelection);
    setFakedSelection([]);
  }, [
    canSelectFuture,
    canSelectPast,
    fakedSelection,
    isSelected,
    selectedDays,
    singleSelection,
  ]);

  const legendModalProps: CustomModalProps = useMemo(
    () => ({
      header: t("common.calendarLegend"),
      isOpen: showLegend,
      onClose: () => setShowLegend(false),
      height: "max-content",
      width: "max-content",
      centered: true,
      justified: true,
      body: legend?.map((x, index) => (
        <div
          className="flex gap-2 w-12 mb-1"
          key={index}
        >
          <div
            style={{
              backgroundColor: x.color,
              borderRadius: "25px",
              width: "25px",
              height: "25px",
            }}
          ></div>
          <div>{x.description}</div>
        </div>
      )),
    }),
    [t, showLegend, legend]
  );

  return (
    <div className="p-2 flex flex-column">
      <CustomModal {...legendModalProps} />
      <div className="calendar-info pagination grid grid-nogutter justify-content-between">
        <div className="col-3 col-sm-8">
          <div className="input-group">
            {showButtons && (
              <div className="input-group-prepend">
                <Button
                  className="settings-button"
                  type="button"
                  icon="pi pi-arrow-left"
                  onClick={() => setSelectedMonth(addMonths(selectedMonth, -1))}
                />
              </div>
            )}
            <Calendar
              value={selectedMonth}
              view="month"
              dateFormat="MM yy"
              yearRange="2023:2077"
              onChange={(e) => setSelectedMonth(e.value as Date)}
              className="calendar-theme"
            />
            {showButtons && (
              <div className="input-group-append">
                <Button
                  className="settings-button"
                  type="button"
                  icon="pi pi-arrow-right"
                  onClick={() => setSelectedMonth(addMonths(selectedMonth, 1))}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-5 text-right">
          <Button
            type="button"
            label={t("common.clearSelection")}
            className="settings-button mr-2"
            severity="secondary"
            icon="pi pi-times"
            onClick={() => {
              setSelectedDays([]);
            }}
            disabled={selectedDays.length === 0}
          />
          <Menu
            model={menuItems}
            popup
            className="min-w-max"
            ref={calendarMenu}
            id="popup_calendar_menu"
          />
          <Button
            icon="pi pi-cog"
            onClick={(event) => (calendarMenu.current! as any).toggle(event)}
            aria-controls="popup_calendar_menu"
            aria-haspopup
          />
        </div>
      </div>
      <div
        className="calendar-body overflow-auto p-1"
        style={{ width: bodyOptions?.width }}
      >
        {daysInWeek.map((day, i) => (
          <div
            key={day}
            className={classNames(
              "cell day-name day-5",
              showWeekend ? "day-7" : "day-5",
              !showWeekend && i >= 5 ? "hide-weekend" : ""
            )}
          >
            {day}
          </div>
        ))}

        {calendarDays.map((calendarDay) => (
          <div
            key={calendarDay.day.toString()}
            className={classNames(
              "cell day",
              isSelectable(calendarDay.day)
                ? "selectable-cell"
                : "not-selectable-cell",
              showWeekend ? "day-7" : "day-5",
              showWeekendForDay(calendarDay.day) ? "" : "hide-weekend",
              calendarDay.day.getMonth() !== selectedMonth.getMonth()
                ? "previous-month-day"
                : "",
              isDayToday(calendarDay.day) ? "today" : "",
              isSelected(calendarDay.day) ? "active" : ""
            )}
            onMouseDown={() => onSelectionStart(calendarDay.day)}
            onMouseEnter={() => onSelectionMove(calendarDay.day)}
            onMouseUp={() => onSelectionEnd()}
          >
            <CalendarDay
              dayStyle={bodyOptions?.calendarDayStyle}
              day={calendarDay.day}
              showUserOnly={bodyOptions?.showUserOnly}
              entries={calendarDay.entries}
              groupEntriesByName={groupEntriesByName}
              notWorkingDay={calendarDay.notWorkingDay}
              holiday={calendarDay.isHoliday}
              warnMissingMobile={warnMissingMobile}
              dayHeader={dayHeader}
            ></CalendarDay>
          </div>
        ))}
      </div>
    </div>
  );
}
