import { Form, Formik } from "formik";
import { Installation } from "../../queries/models/installation.model";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { AddOrEditInstallation } from "../../queries/models/form-installation.model";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";
import { useCallback, useMemo } from "react";
import { useCountriesAsSelectOptionsQuery } from "../../queries/countries.query";
import { useGeolocationFeedbackMutation } from "../../queries/geolocation-query";
import { EditCoordinatesComponent } from "../../components/ui/google-map/edit-coordinates-component";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import FormikSlider from "../../components/ui/formik/FormikSlider";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import { Button } from "primereact/button";
import { useToast } from "../../components/ui/toast-context-provider";
import { useWindowSize } from "../../hooks/use-window-size";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { phoneRegExp } from "../../utils/phoneRegExp";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

export interface InstallationFormProps {
  installation: Installation;
  onSave: (form: AddOrEditInstallation) => Promise<any> | void;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
  mobileView?: boolean;
}

export function InstallationForm({
  installation,
  onSave,
  onCancel,
  onDelete,
}: InstallationFormProps) {
  const countriesOptionsQuery = useCountriesAsSelectOptionsQuery();
  const activeUsersAsOptionsQuery = useActiveUsersAsSelectOptionQuery();
  const toast = useToast();
  const { lg } = useWindowSize() || {};
  const { t } = useTranslation();
  const hasWriteAccess = useMemo(() => {
    return authService.hasAccess(AppFeatures.WebInstallationsWrite);
  }, []);

  const initialValues: AddOrEditInstallation = {
    id: installation?.id ?? 0,
    name: installation?.name ?? "",
    contactName: installation?.contactName ?? "",
    contactPhone: installation?.contactPhone ?? "",
    contactEmail: installation?.contactEmail ?? "",
    address: {
      radius: installation?.address?.radius ?? 50,
      latitude: installation?.address?.latitude,
      longitude: installation?.address?.longitude,
      city: installation?.address?.city ?? "",
      street: installation?.address?.street ?? "",
      postalCode: installation?.address?.postalCode ?? "",
      countryId: installation?.address?.countryId ?? 0,
      id: installation?.addressId ?? 0,
    },
    assignedUserIds: installation?.assignedUserIds ?? [],
  };

  const geolocationMutation = useGeolocationFeedbackMutation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Must be at least 3 characters")
      .required("Required"),
    contactName: Yup.string().min(3, "Must be at least 3 characters"),
    contactPhone: Yup.string().matches(
      phoneRegExp,
      "Phone number format invalid"
    ),
    contactEmail: Yup.string().email("Contact email must be valid email"),
    address: Yup.object()
      .shape({
        countryId: Yup.number()
          .min(1, "Select country")
          .required("Select country"),
        city: Yup.string()
          .required("Required")
          .min(3, "Must be at least 3 characters"),
        street: Yup.string()
          .required("Required")
          .min(3, "Must be at least 3 characters"),
        postalCode: Yup.string().required("Required"),
        radius: Yup.number().min(10).max(500).default(50).required(),
        latitude: Yup.number().required("Required"),
        longitude: Yup.number().required("Required"),
      })
      .required("Required"),
    assignedUserIds: Yup.array(),
  });

  const onSubmit = useCallback(
    (values: AddOrEditInstallation) => {
      if (!hasWriteAccess) {
        return;
      }
      return onSave(values);
    },
    [hasWriteAccess, onSave]
  );

  return (
    <RoundedShadowContainer
      small
      fullHeight
      mobile={lg}
    >
      <LoaderWrapper
        isLoading={
          countriesOptionsQuery.isLoading || activeUsersAsOptionsQuery.isLoading
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form className="w-full h-full">
              <div className="h-full flex flex-column">
                <div className="p-2 h-3rem">
                  <EditContentHeader
                    header={installation?.name ?? "Add new user"}
                    showDeleteButton={hasWriteAccess && !!installation.id}
                    saveButtonDisabled={
                      !hasWriteAccess || !formik.isValid || !formik.dirty
                    }
                    onSaveClick={async () => {
                      if (!formik.isValid) {
                        toast.current?.show({
                          severity: "error",
                          detail: "Form invalid",
                        });
                        return;
                      }
                      return formik.submitForm();
                    }}
                    onCancelClick={onCancel}
                    onDeleteClick={onDelete}
                  />
                </div>
                <div
                  className="p-2 overflow-x-hidden flex flex-column"
                  style={{ height: "calc(100% - 3rem)" }}
                >
                  <div
                    className="formgrid grid"
                    style={{ flex: "0" }}
                  >
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.name")}
                        name="name"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.contactName")}
                        name="contactName"
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.email")}
                        name="contactEmail"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label={t("common.contactPhone")}
                        name="contactPhone"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="w-12">
                      <div className="field col-12 md:col-6">
                        <FormikMultiSelect
                          label="Select users"
                          name="assignedUserIds"
                          validationSchema={validationSchema}
                          options={activeUsersAsOptionsQuery.data}
                          showFilter
                          disabled={!hasWriteAccess}
                        />
                      </div>
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikDropDown
                        label="Country"
                        options={countriesOptionsQuery.data}
                        name="address.countryId"
                        validationSchema={validationSchema}
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="City"
                        name="address.city"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="Street"
                        name="address.street"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="Postal code"
                        name="address.postalCode"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-12">
                      <FormikSlider
                        label="Radius"
                        name="address.radius"
                        validationSchema={validationSchema}
                        nested
                        isIndependent
                        step={10}
                        disabled={!hasWriteAccess}
                      />
                    </div>
                    <div className="field col-12 md:col-12">
                      <Button
                        className="yellow-button w-full my-2"
                        style={{
                          height: "39px",
                          outline:
                            (formik.errors.address?.latitude ||
                              formik.errors.address?.longitude) &&
                            "1px solid red",
                        }}
                        type="button"
                        label="Get coordinates"
                        disabled={
                          !hasWriteAccess ||
                          (formik.values.address.city &&
                          formik.values.address.countryId &&
                          formik.values.address.street
                            ? false
                            : true)
                        }
                        loading={geolocationMutation.isLoading}
                        onClick={() => {
                          geolocationMutation
                            .mutateAsync({
                              country:
                                countriesOptionsQuery.data?.find(
                                  (x) =>
                                    x.value === formik.values.address.countryId
                                )?.label ?? "",
                              city: formik.values.address.city ?? "",
                              street: formik.values.address.street ?? "",
                            })
                            .then(
                              (result) => {
                                formik.setValues((x) => ({
                                  ...x,
                                  address: {
                                    ...x.address,
                                    city: result.city ?? "",
                                    street: result.street ?? "",
                                    latitude: result.latitude ?? "",
                                    longitude: result.longitude ?? "",
                                    postalCode: result.postalCode ?? "",
                                  },
                                }));
                                toast.current?.show({
                                  severity: "success",
                                  detail: `Found "${result.readableAddress}"`,
                                });
                              },
                              (err) => {
                                toast.current?.show({
                                  severity: "error",
                                  detail:
                                    typeof err?.data === "string" && err.data,
                                });
                              }
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={lg ? "" : "h-15rem"}
                    style={{
                      flex: "1",
                      minHeight: "200px",
                      outline:
                        (formik.errors.address?.latitude ||
                          formik.errors.address?.longitude) &&
                        "1px solid red",
                    }}
                  >
                    <EditCoordinatesComponent
                      latitude={formik.values.address.latitude}
                      longitude={formik.values.address.longitude}
                      radius={formik.values.address.radius}
                      onCoordinatesChanged={(e: google.maps.MapMouseEvent) => {
                        formik.setValues((x) => ({
                          ...x,
                          address: {
                            ...x.address,
                            longitude: e.latLng?.lng(),
                            latitude: e.latLng?.lat(),
                          },
                        }));
                      }}
                      zoom={12}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </LoaderWrapper>
    </RoundedShadowContainer>
  );
}
