import { useState, useCallback } from "react";
import { useGetBuildingPlanFloorPlansQuery } from "../../../../queries/floor-plan.query";
import { FloorPlan } from "../../../../queries/models/InstallationPlans/floor-plan.model";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { TableHeader } from "../../../../components/ui/table-header";
import { FloorPlanForm } from "../../../../components/buildingPlan/floor-plan/FloorPlanForm";
import { CreateOrUpdateFloorPlanGeneralInfo } from "../../../../queries/models/InstallationPlans/create-or-update-floor-plan-general-info.model";
import { useAddFloorPlanWithGeneralInfoMutation } from "../../../../queries/floor-plan.query";
import { useUpdateFloorPlanGeneralInfoMutation } from "../../../../queries/floor-plan.query";
import { useDeleteFloorPlanMutation } from "../../../../queries/floor-plan.query";
import { useToast } from "../../../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { format } from "date-fns";
import {
  CustomModal,
  CustomModalProps,
} from "../../../../components/ui/MobileModal/custom-modal";
import { LoaderWrapper } from "../../../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

export function InstallationFloorPlans({
  buildingPlanId,
  installationId,
  hasWriteAccess = false,
}: {
  buildingPlanId: number | undefined;
  installationId: number;
  hasWriteAccess?: boolean;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const floorPlansQuery = useGetBuildingPlanFloorPlansQuery(buildingPlanId);
  const addFloorPlanMutation = useAddFloorPlanWithGeneralInfoMutation();
  const updateFloorPlanMutation = useUpdateFloorPlanGeneralInfoMutation();
  const deleteFloorPlanMutation = useDeleteFloorPlanMutation();
  const [selectedFloorPlan, setSelectedFloorPlan] = useState<
    FloorPlan | undefined
  >(undefined);
  const [floorPlanToDeleteId, setFloorPlanToDeleteId] = useState<
    number | undefined
  >(undefined);

  const handleSaveFloorPlan = useCallback(
    async (values: CreateOrUpdateFloorPlanGeneralInfo) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: values.id ? t("alert.editSuccess") : t("alert.saveSuccess"),
          });
          await queryClient.invalidateQueries([
            "buildingPlanFloorPlans",
            buildingPlanId,
          ]);
          setSelectedFloorPlan(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      var request: CreateOrUpdateFloorPlanGeneralInfo = {
        ...values,
        buildingPlanId: buildingPlanId ?? 0,
      };

      return request.id
        ? updateFloorPlanMutation.mutateAsync(request, mutateOptions)
        : addFloorPlanMutation.mutateAsync(request, mutateOptions);
    },
    [
      addFloorPlanMutation,
      buildingPlanId,
      queryClient,
      t,
      toast,
      updateFloorPlanMutation,
    ]
  );

  const handleDeleteFloorPlan = useCallback(async () => {
    if (selectedFloorPlan?.id) {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.deleteSuccess"),
          });
          await queryClient.invalidateQueries([
            "buildingPlanFloorPlans",
            buildingPlanId,
          ]);
          setSelectedFloorPlan(undefined);
          setFloorPlanToDeleteId(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };
      await deleteFloorPlanMutation.mutateAsync(
        selectedFloorPlan.id,
        mutateOptions
      );
    }
  }, [
    buildingPlanId,
    deleteFloorPlanMutation,
    queryClient,
    selectedFloorPlan?.id,
    t,
    toast,
  ]);

  const deleteModalProps: CustomModalProps = {
    isOpen: !!floorPlanToDeleteId,
    header: t("common.confirmation"),
    body: t("dialog.deleteThisFloorPlan"),
    onClose: () => setFloorPlanToDeleteId(undefined),
    onConfirm: handleDeleteFloorPlan,
    confirmation: true,
    justified: true,
    centered: true,
    height: "max-content",
  };

  return (
    <div className="h-full relative">
      <CustomModal {...deleteModalProps} />
      <LoaderWrapper isLoading={floorPlansQuery.isLoading}>
        <div className="h-full">
          {!!selectedFloorPlan ? (
            <div className="p-2 h-full">
              <FloorPlanForm
                floorPlan={selectedFloorPlan}
                installationId={installationId}
                onSave={handleSaveFloorPlan}
                onDelete={setFloorPlanToDeleteId}
                onCancel={() => setSelectedFloorPlan(undefined)}
              />
            </div>
          ) : (
            <DataTable
              header={
                <TableHeader
                  header={t("common.floorPlans")}
                  buttonLabel={t("common.add")}
                  showButton
                  onClick={() => {
                    setSelectedFloorPlan({ name: "" } as FloorPlan);
                  }}
                  buttonDisabled={!hasWriteAccess}
                />
              }
              scrollable
              scrollHeight="flex"
              value={floorPlansQuery.data}
              resizableColumns
            >
              <Column
                field="name"
                header={t("common.name")}
                sortable
              />
              <Column
                dataType="date"
                field="dateCreated"
                header={t("common.dateCreated")}
                body={(x) => format(x.dateCreated ?? 0, "dd/LL/yyyy")}
                sortable
              />
              <Column
                header={t("common.action")}
                body={(x) => (
                  <Button
                    className="green-action-button h-2rem"
                    label={t("common.edit")}
                    onClick={() => setSelectedFloorPlan(x)}
                    disabled={!hasWriteAccess}
                  />
                )}
              />
            </DataTable>
          )}
        </div>
      </LoaderWrapper>
    </div>
  );
}
