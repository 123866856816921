import { useCallback, useState, BaseSyntheticEvent } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "../../queries/models/tag.model";
import { Loader } from "../../components/ui/Loader";
import { TableHeader } from "../../components/ui/table-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

interface TagsOverviewProps {
  items: Tag[] | undefined;
  isLoading: boolean;
  selectedTag: Tag | undefined;
  setSelectedTag: (tag: Tag | undefined) => void;
}

export function TagsOverview({
  items,
  isLoading,
  selectedTag,
  setSelectedTag,
}: TagsOverviewProps) {
  const { t } = useTranslation();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const colorRowDataTemplate = useCallback(
    (color: string) => (
      <div
        style={{
          backgroundColor: color,
          borderRadius: ".7rem",
          border: "1px solid black",
          height: "1.3rem",
          width: "5rem",
        }}
      ></div>
    ),
    []
  );

  const onGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      let _filters = { ...filters };
      const value = e.target.value;

      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  return (
    <div className="h-full">
      {!isLoading ? (
        <div className="h-full">
          <div className="h-3rem py-1 px-2">
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              className="w-full"
              placeholder={t("common.filter")}
            />
          </div>
          <DataTable
            value={items}
            className="tags-table"
            style={{ height: "calc(100% - 3rem)" }}
            header={
              <TableHeader
                header={t("common.tags")}
                showButton
                onClick={() => setSelectedTag({ name: "" } as Tag)}
              />
            }
            scrollable
            scrollHeight="flex"
            selectionMode="single"
            selection={selectedTag}
            onSelectionChange={(e) => setSelectedTag(e.value as Tag)}
            globalFilterFields={["name", "icon"]}
            filters={filters}
          >
            <Column
              header={t("common.name")}
              field="name"
              sortable
            />
            <Column
              header={t("common.textColor")}
              field="textColor"
              body={(x) => colorRowDataTemplate(x.textColor)}
            />
            <Column
              header={t("common.backgroundColor")}
              field="backgroundColor"
              body={(x) => colorRowDataTemplate(x.backgroundColor)}
            />
            <Column
              header={t("common.icon")}
              field="icon"
              body={(x) =>
                x.icon && (
                  <FontAwesomeIcon
                    icon={x.icon}
                    size="2x"
                  />
                )
              }
            />
          </DataTable>
        </div>
      ) : (
        <div className="h-full flex justify-content-center align-items-center">
          <Loader type="spinner" />
        </div>
      )}
    </div>
  );
}
