import { BaseSyntheticEvent, useCallback, useState, useMemo } from "react";
import { useUsersQuery } from "../queries/users.query";
import { useDeleteUserMutation } from "../queries/users.query";
import { useAddUserMutation } from "../queries/users.query";
import { useEditUserMutation } from "../queries/users.query";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../components/ui/table-header";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { User } from "../queries/models/user.model";
import { useToast } from "../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { UserForm } from "./Users/UserForm";
import { CreateUserRequest } from "../queries/models/create-user-request.model";
import { UpdateUserRequest } from "../queries/models/update-user-request";
import { AddOrEditUser } from "../queries/models/form-user.model";
import {
  CustomModal,
  CustomModalProps,
} from "../components/ui/MobileModal/custom-modal";
import { FilterMatchMode } from "primereact/api";
import { LoaderWrapper } from "../components/ui/LoaderWrapper";
import { authService } from "../services/auth.service";
import { AppFeatures } from "../queries/models/enums/app-feature-enum";

export function MobileUsers() {
  const queryClient = useQueryClient();
  const usersQuery = useUsersQuery();
  const addUserMutation = useAddUserMutation();
  const editUserMutation = useEditUserMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const toast = useToast();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebUsersScreen),
    []
  );

  const handleGlobalFilterChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const value = e.target.value;
      let _filters = filters;
      _filters.global.value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    },
    [filters]
  );

  const handleAddNewUser = () => {
    setSelectedUser({
      username: "",
    } as User);
  };

  const handleSaveUser = (data: AddOrEditUser) => {
    return data?.id ? editExistingUser(data) : saveNewUser(data);
  };

  const saveNewUser = (data: AddOrEditUser) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedUser(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Save success",
        });
        await queryClient.invalidateQueries("users");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error?.data,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: CreateUserRequest = {
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      contactEmail: data.contactEmail,
      password: data.password ?? "",
      username: data.username,
      roleId: data.roleId,
      defaultLanguage: data.defaultLanguage,
      assignedInstallationIds: data.assignedInstallationIds ?? [],
      includePauseTimeForInvoicing: data.includePauseTimeForInvoicing,
    };

    return addUserMutation.mutateAsync(request, mutateOptions);
  };

  const editExistingUser = (data: AddOrEditUser) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        setSelectedUser(undefined);
        toast.current?.show({
          severity: "success",
          detail: "Edit success",
        });
        await queryClient.invalidateQueries("users");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error?.data,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
        setIsModalOpen(false);
      },
    };

    const request: UpdateUserRequest = {
      id: data.id,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      contactEmail: data.contactEmail,
      defaultLanguage: data.defaultLanguage,
      password: data.password ?? "",
      username: data.username,
      roleId: data.roleId,
      assignedInstallationIds: data.assignedInstallationIds ?? [],
      includePauseTimeForInvoicing: data.includePauseTimeForInvoicing,
    };

    return editUserMutation.mutateAsync(request, mutateOptions);
  };

  const mobileUserFormModalProps: CustomModalProps = {
    header: selectedUser?.id ? selectedUser.username : "Add new user",
    onClose: () => {
      setSelectedUser(undefined);
      setIsModalOpen(false);
    },
    isOpen: isModalOpen,
    body: (
      <UserForm
        user={selectedUser ?? ({} as User)}
        onSave={handleSaveUser}
        onCancel={() => {
          setSelectedUser(undefined);
          setIsModalOpen(false);
        }}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
        isSubmitting={isSubmitting}
        hasWriteAccess={hasWriteAccess}
      />
    ),
  };

  const handleDeleteUser = useCallback(() => {
    if (selectedUser) {
      deleteUserMutation.mutateAsync(selectedUser.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries("users");
          toast.current?.show({
            severity: "success",
            detail: "User successfully deleted",
          });
          setSelectedUser(undefined);
          setIsDeleteConfirmationModalOpen(false);
          setIsModalOpen(false);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      });
    }
  }, [deleteUserMutation, queryClient, selectedUser, toast]);

  const mobileModalConfirmationFormProps: CustomModalProps = {
    header: "Confirmation",
    body: "Delete this user?",
    height: "160px",
    width: "50%",
    isOpen: isDeleteConfirmationModalOpen,
    confirmation: true,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    centered: true,
    justified: true,
    onConfirm: handleDeleteUser,
  };

  return (
    <div className=" h-full ">
      <LoaderWrapper isLoading={usersQuery.isLoading}>
        <div className="h-full">
          {!!mobileUserFormModalProps && (
            <CustomModal {...mobileUserFormModalProps} />
          )}
          {!!mobileModalConfirmationFormProps && (
            <CustomModal {...mobileModalConfirmationFormProps} />
          )}
          <div className="p-1 h-3rem">
            <InputText
              className="w-full"
              placeholder="Filter"
              value={globalFilterValue}
              onChange={handleGlobalFilterChange}
            />
          </div>

          <div style={{ height: "calc(100% - 3rem)" }}>
            <DataTable
              value={usersQuery.data}
              resizableColumns={true}
              scrollable={true}
              scrollHeight="flex"
              selectionMode="single"
              selection={selectedUser}
              onSelectionChange={(e) => {
                setSelectedUser(e.value as User);
                setIsModalOpen(true);
              }}
              filters={filters}
              globalFilterFields={["username", "contactPhone"]}
              header={
                <TableHeader
                  header="Users"
                  showButton={true}
                  onClick={() => {
                    handleAddNewUser();
                    setIsModalOpen(true);
                  }}
                />
              }
            >
              <Column
                field="username"
                header="Username"
                sortable
              />
              <Column
                field="contactPhone"
                header="Contact phone"
                sortable
              />
            </DataTable>
          </div>
        </div>
      </LoaderWrapper>
    </div>
  );
}
