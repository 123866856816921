import { useMemo } from "react";
import Enumerable from "linq";
import { WorkSectionComponentProps } from "../WorkSectionComponent";
import { OrderStatus } from "../../../queries/models/enums/order-status.enum";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { format } from "date-fns";
import { ValidatedWorkInstance } from "../../../queries/models/validated-work-instance.model";
import { InstallatorTravelOrder } from "../../../queries/models/orders/instllator-travel-order.model";
import { InstallatorWorkTimeOrder } from "../../../queries/models/orders/instllator-worktime-order.model";
import { useTranslation } from "react-i18next";

export function MobileWorkSectionComponent({
  validation,
  selectedItem: selectedInstance,
  onSelectItem,
  selectedOverviewItem,
  onSendUserOrders,
  onSendWorkInstanceOrders,
}: WorkSectionComponentProps) {
  const { t } = useTranslation();
  const totalPendingOrders = useMemo(
    () =>
      Enumerable.from(validation?.workInstances ?? [])
        .selectMany<InstallatorTravelOrder | InstallatorWorkTimeOrder>(
          (x) => x.installatorTravelOrders
        )
        .concat(
          Enumerable.from(validation?.workInstances ?? []).selectMany<
            InstallatorTravelOrder | InstallatorWorkTimeOrder
          >((x) => x.installatorWorkTimeOrders)
        )
        .where((x) => x.status !== OrderStatus.Sent)
        .count(),
    [validation]
  );

  const header = (
    <div className="flex justify-content-between align-items-center">
      <div>Work instances</div>
      <Button
        label={`${t("common.sendAll")} (${totalPendingOrders})`}
        className="green-action-button"
        disabled={
          totalPendingOrders === 0 || !selectedOverviewItem?.hasOdooIdMapping
        }
        style={{ width: "max-content" }}
        onClick={() => onSendUserOrders(validation!)}
      />
    </div>
  );

  return (
    <>
      {validation && (
        <div>
          <div className="text-xl font-bold ml-1">
            {validation?.user.username}
            {!selectedOverviewItem?.hasOdooIdMapping && (
              <span
                className="text-red-500"
                style={{ whiteSpace: "pre" }}
              >
                {` (${t("common.missingOdooId")})`}
              </span>
            )}
          </div>
          <DataTable
            header={header}
            className="mobile-work-instances-table z-0"
            value={validation?.workInstances}
            scrollable={true}
            dataKey="id"
            style={{ maxHeight: "40vh" }}
            scrollHeight="flex"
            selectionMode="single"
            selection={selectedInstance}
            onSelectionChange={(e) => {
              onSelectItem(e.value as ValidatedWorkInstance | undefined);
            }}
          >
            <Column
              header={t("common.installation")}
              body={(x) =>
                `${x.installation.name} - ${format(
                  x.dateStarted,
                  "dd/LL/yyyy"
                )}`
              }
            />
            <Column
              header={t("common.action")}
              body={(x) => {
                const pendingOrders = [
                  ...x.installatorTravelOrders.filter(
                    (e: InstallatorTravelOrder) => e.status !== OrderStatus.Sent
                  ),
                  ...x.installatorWorkTimeOrders.filter(
                    (e: InstallatorWorkTimeOrder) =>
                      e.status !== OrderStatus.Sent
                  ),
                ].length;
                return (
                  <Button
                    className="green-action-button"
                    style={{ width: "max-content" }}
                    disabled={
                      pendingOrders === 0 ||
                      !selectedOverviewItem?.hasOdooIdMapping
                    }
                    label={`${t("common.send")} (${pendingOrders})`}
                    onClick={() => onSendWorkInstanceOrders(x)}
                  />
                );
              }}
            />
          </DataTable>
        </div>
      )}

      {!validation && (
        <div
          style={{ height: "8vh" }}
          className="flex justify-content-center align-items-center text-xl font-bold text-500"
        >
          {t("common.SELECT_USER")}
        </div>
      )}
    </>
  );
}
