import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useState, useCallback, useMemo } from "react";
import { RolesForm } from "./RolesForm";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { TableHeader } from "../../components/ui/table-header";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { Role } from "../../queries/models/role-model";
import {
  useAddRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useRolesQuery,
} from "../../queries/roles.query";
import { CreateRoleRequest } from "../../queries/models/create-role-request";
import { AddOrEditRole } from "../../queries/models/add-edit-role";
import { UpdateRoleRequest } from "../../queries/models/update-role-request";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

export function Roles() {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState<Role>();
  const rolesQuery = useRolesQuery();
  const deleteRoleMutation = useDeleteRoleMutation();
  const addRoleMutation = useAddRoleMutation();
  const editRoleMutation = useEditRoleMutation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);

  const handleAddNewRole = () => {
    setSelectedRole({
      name: "",
    } as Role);
  };

  const saveNewRole = useCallback(
    (data: AddOrEditRole) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedRole(undefined);
          toast.current?.show({
            severity: "success",
            detail: t("alert.saveSuccess"),
          });
          await queryClient.invalidateQueries("roles");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateRoleRequest = {
        name: data.name,
        description: data.description,
        appFeatures: data.appFeatures ?? [],
      };

      return addRoleMutation.mutateAsync(request, mutateOptions);
    },
    [addRoleMutation, queryClient, t, toast]
  );

  const editExistingRole = useCallback(
    (data: AddOrEditRole) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedRole(undefined);
          toast.current?.show({
            severity: "success",
            detail: t("alert.editSuccess"),
          });
          await queryClient.invalidateQueries("roles");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: UpdateRoleRequest = {
        id: data.id,
        name: data.name,
        description: data.description,
        appFeatures: data.appFeatures ?? [],
      };

      return editRoleMutation.mutateAsync(request, mutateOptions);
    },
    [editRoleMutation, queryClient, t, toast]
  );

  const handleSaveRole = useCallback(
    (data: AddOrEditRole) => {
      return data.id === 0 ? saveNewRole(data) : editExistingRole(data);
    },
    [editExistingRole, saveNewRole]
  );

  const handleDeleteRole = useCallback(() => {
    if (selectedRole?.id) {
      deleteRoleMutation.mutateAsync(selectedRole.id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries("roles");
          toast.current?.show({
            severity: "success",
            detail: t("alert.roleSuccessfullyDeleted"),
          });
          setSelectedRole(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      });
      setIsDeleteConfirmationModalOpen(false);
    }
  }, [selectedRole?.id, deleteRoleMutation, queryClient, toast, t]);

  const deleteConfirmationModalProps: CustomModalProps = useMemo(() => {
    return {
      header: t("common.confirmation"),
      body: t("dialog.deleteThisRole"),
      height: "160px",
      isOpen: isDeleteConfirmationModalOpen,
      confirmation: true,
      centered: true,
      justified: true,
      onClose: () => setIsDeleteConfirmationModalOpen(false),
      onConfirm: () => handleDeleteRole(),
    };
  }, [handleDeleteRole, isDeleteConfirmationModalOpen, t]);

  return (
    <div className="flex gap-2 p-1 h-full">
      <CustomModal {...deleteConfirmationModalProps} />
      <div className="w-6 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={rolesQuery.isLoading}>
            <DataTable
              className="h-full"
              scrollable={true}
              scrollHeight="flex"
              value={rolesQuery.data}
              header={
                <TableHeader
                  header={t("common.roles")}
                  showButton={true}
                  onClick={handleAddNewRole}
                />
              }
              filterDisplay="row"
              selectionMode="single"
              resizableColumns={false}
              selection={selectedRole}
              onSelectionChange={(e) => {
                setSelectedRole(e.value as Role);
              }}
            >
              <Column
                field="name"
                header={t("common.name")}
                className="w-3"
                sortable
                filter
                filterMatchMode="contains"
                filterPlaceholder={t("common.filter")}
                showFilterMenu={false}
              />
              <Column
                field="description"
                header={t("common.description")}
                className="w-3"
                sortable
                filter
                filterMatchMode="contains"
                filterPlaceholder={t("common.filter")}
                showFilterMenu={false}
              />
            </DataTable>
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className="w-6 h-full ">
        <RoundedShadowContainer
          small
          fullHeight
        >
          {selectedRole ? (
            <RolesForm
              role={selectedRole}
              onSave={handleSaveRole}
              onCancel={() => setSelectedRole(undefined)}
              onDelete={() => setIsDeleteConfirmationModalOpen(true)}
            />
          ) : (
            <div className="w-full h-full flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faUserPlus}
                style={{ fontSize: 270 }}
                color="#7C4DFF"
                className="cursor-pointer"
                onClick={handleAddNewRole}
              />
            </div>
          )}
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
