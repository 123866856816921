import { useCallback } from "react";
import { User } from "../../queries/models/user.model";
import { useInstallationsOptionsQuery } from "../../queries/installations.query";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import { AddOrEditUser } from "../../queries/models/form-user.model";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";
import { useWindowSize } from "../../hooks/use-window-size";
import { useToast } from "../../components/ui/toast-context-provider";
import { useUserHasPendingWorkQuery } from "../../queries/planning.query";
import { confirmDialog } from "primereact/confirmdialog";
import { phoneRegExp } from "../../utils/phoneRegExp";
import { useRoleOptionsQuery, useRolesQuery } from "../../queries/roles.query";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import FormikInputPassword from "../../components/ui/formik/formikInputPassword";
import { usePublishedLanguagesOptionsQuery } from "../../queries/languages.query";
import { useMemo } from "react";
import { SelectItem } from "primereact/selectitem";
import { useTranslation } from "react-i18next";
import { useLoggedUser } from "../../hooks/useLoggedUser";

export interface UserEditFormProps {
  user: User;
  isSubmitting?: boolean;
  onSave: (form: AddOrEditUser) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
  hasWriteAccess?: boolean;
}

export function UserForm({
  user,
  onSave,
  onCancel,
  onDelete,
  hasWriteAccess = false,
}: UserEditFormProps) {
  const { t } = useTranslation();
  const { lg } = useWindowSize() || {};
  const installationsOptionsQuery = useInstallationsOptionsQuery();
  const rolesQuery = useRolesQuery();
  const rolesOptionsQuery = useRoleOptionsQuery();
  const languagesQuery = usePublishedLanguagesOptionsQuery();
  const userHasPendingWorkQuery = useUserHasPendingWorkQuery(user?.id);
  const toast = useToast();
  const loggedUser = useLoggedUser();

  const languagesOptions: SelectItem[] = useMemo(
    () =>
      languagesQuery.data?.sort((a, b) =>
        a.label!.toLowerCase() > b.label!.toLowerCase() ? 1 : -1
      ) ?? [],
    [languagesQuery.data]
  );

  const initialValues: AddOrEditUser = {
    username: user?.username ?? "",
    contactName: user?.contactName ?? "",
    contactPhone: user?.contactPhone ?? "",
    contactEmail: user?.contactEmail ?? "",
    defaultLanguage: user?.defaultLanguage ?? "",
    billingInfo_BillToName: user?.billingInfo_BillToName ?? "",
    billingInfo_City: user?.billingInfo_City ?? "",
    billingInfo_PostalCode: user?.billingInfo_PostalCode ?? "",
    billingInfo_Street: user?.billingInfo_Street ?? "",
    billingInfo_Phone: user?.billingInfo_Phone ?? "",
    billingInfo_Email: user?.billingInfo_Email ?? "",
    billingInfo_VatEu: user?.billingInfo_VatEu ?? "",
    assignedInstallationIds: user?.assignedInstallationIds ?? [],
    roleId: user?.roleId ?? undefined,
    password: "" ?? undefined,
    passwordRepeat: "" ?? undefined,
    id: user?.id ?? 0,
    includePauseTimeForInvoicing: user?.includePauseTimeForInvoicing ?? false,
  };

  const isBillingInfoRequired = useCallback(
    (formik: any) => {
      return (
        loggedUser?.companyOdooIntegrated &&
        rolesQuery.data &&
        rolesQuery.data
          .find((x) => x.id === formik.values.roleId)
          ?.appFeatures.includes(AppFeatures.MobileAccess)
      );
    },
    [loggedUser?.companyOdooIntegrated, rolesQuery.data]
  );

  const validationSchema = Yup.object({
    username: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .required(t("alert.required")),
    contactName: Yup.string().optional(),
    includePauseTimeForInvoicing: Yup.boolean().optional(),
    contactPhone: Yup.string().matches(
      phoneRegExp,
      t("alert.phoneNumberFormatInvalid")
    ),
    contactEmail: Yup.string().optional().email(t("alert.emailFormatInvalid")),
    defaultLanguage: Yup.string().required(t("alert.required")),
    billingInfo_Email: Yup.string()
      .optional()
      .email(t("alert.emailFormatInvalid"))
      .when(["roleId"], (roleId, schema) => {
        return roleId &&
          loggedUser?.companyOdooIntegrated &&
          rolesQuery.data &&
          rolesQuery.data
            .find((x) => x.id === roleId[0])
            ?.appFeatures.includes(AppFeatures.MobileAccess)
          ? schema.required(t("alert.required")).nonNullable()
          : schema.nullable().optional();
      }),
    billingInfo_Street: Yup.string()
      .optional()
      .when(["roleId"], (roleId, schema) => {
        return roleId &&
          loggedUser?.companyOdooIntegrated &&
          rolesQuery.data &&
          rolesQuery.data
            .find((x) => x.id === roleId[0])
            ?.appFeatures.includes(AppFeatures.MobileAccess)
          ? schema.required(t("alert.required"))
          : schema.nullable().optional();
      }),
    billingInfo_BillToName: Yup.string()
      .optional()
      .when(["roleId"], (roleId, schema) => {
        return roleId &&
          loggedUser?.companyOdooIntegrated &&
          rolesQuery.data &&
          rolesQuery.data
            .find((x) => x.id === roleId[0])
            ?.appFeatures.includes(AppFeatures.MobileAccess)
          ? schema.required(t("alert.required"))
          : schema.nullable().optional();
      }),
    billingInfo_City: Yup.string()
      .optional()
      .when(["roleId"], (roleId, schema) => {
        return roleId &&
          loggedUser?.companyOdooIntegrated &&
          rolesQuery.data &&
          rolesQuery.data
            .find((x) => x.id === roleId[0])
            ?.appFeatures.includes(AppFeatures.MobileAccess)
          ? schema.required(t("alert.required"))
          : schema.nullable().optional();
      }),
    billingInfo_PostalCode: Yup.string()
      .optional()
      .when(["roleId"], (roleId, schema) => {
        return roleId &&
          loggedUser?.companyOdooIntegrated &&
          rolesQuery.data &&
          rolesQuery.data
            .find((x) => x.id === roleId[0])
            ?.appFeatures.includes(AppFeatures.MobileAccess)
          ? schema.required(t("alert.required"))
          : schema.nullable().optional();
      }),
    billingInfo_Phone: Yup.string()
      .matches(phoneRegExp, t("alert.phoneNumberFormatInvalid"))
      .optional()
      .when(["roleId"], (roleId, schema) => {
        return roleId &&
          loggedUser?.companyOdooIntegrated &&
          rolesQuery.data &&
          rolesQuery.data
            .find((x) => x.id === roleId[0])
            ?.appFeatures.includes(AppFeatures.MobileAccess)
          ? schema.required(t("alert.required"))
          : schema.nullable().optional();
      }),
    billingInfo_VatEu: Yup.string()
      .optional()
      .when(["roleId"], (roleId, schema) => {
        return roleId &&
          loggedUser?.companyOdooIntegrated &&
          rolesQuery.data &&
          rolesQuery.data
            .find((x) => x.id === roleId[0])
            ?.appFeatures.includes(AppFeatures.MobileAccess)
          ? schema.required(t("alert.required"))
          : schema.nullable().optional();
      }),
    assignedInstallationIds: Yup.array(),
    roleId: Yup.number().required(t("alert.selectRoleIsARequiredField")),
    password: Yup.string()
      .optional()
      .min(8, t("alert.mustBe8CharactersOrMore")),
    passwordRepeat: Yup.string()
      .optional()
      .oneOf([Yup.ref("password")], t("alert.passwordMustMatch"))
      .when(["password"], (password, schema) => {
        return password[0] !== undefined && (password[0] as any) !== ""
          ? schema.required(t("alert.required"))
          : schema.nullable().optional();
      }),
  });

  const onSubmit = useCallback(
    (values: AddOrEditUser) => {
      if (!hasWriteAccess) return;
      const initRole = rolesQuery.data?.find(
        (x) => x.id === initialValues.roleId
      );
      const newRole = rolesQuery.data?.find((x) => x.id === values.roleId);

      if (
        userHasPendingWorkQuery.data &&
        initRole?.appFeatures.includes(AppFeatures.MobileAccess) &&
        !newRole?.appFeatures.includes(AppFeatures.MobileAccess)
      ) {
        confirmDialog({
          message: t(
            "dialog.userHasSomePendingWork.AreYouSureYouWantToDisactiveMobileRoleOnIt"
          ),
          header: t("common.confirmation"),
          icon: "pi pi-exclamation-triangle text-yellow-600",
          accept: () => {
            return onSave(values);
          },
        });
      } else {
        return onSave(values);
      }
    },
    [
      hasWriteAccess,
      rolesQuery.data,
      userHasPendingWorkQuery.data,
      initialValues.roleId,
      t,
      onSave,
    ]
  );

  return (
    <div className=" h-full w-full">
      <LoaderWrapper
        isLoading={
          installationsOptionsQuery.isLoading || languagesQuery.isLoading
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form
              autoComplete="off"
              className={classNames(
                "flex overflow-x-hidden",
                lg ? "flex-column" : " flex-column-reverse"
              )}
            >
              <div className="p-2">
                <EditContentHeader
                  header={user?.id ? user.username : "Add new user"}
                  showDeleteButton={hasWriteAccess && !!user.id}
                  saveButtonDisabled={
                    !hasWriteAccess || !formik.dirty || !formik.isValid
                  }
                  deleteButtonDisabled={user.id === loggedUser?.id}
                  onSaveClick={async () => {
                    if (!formik.isValid) {
                      toast.current?.show({
                        severity: "error",
                        detail: t("alert.formInvalid"),
                      });
                      return;
                    }
                    return formik.submitForm();
                  }}
                  onCancelClick={onCancel}
                  onDeleteClick={onDelete}
                />
              </div>
              <div className="formgrid grid p-2">
                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label={t("common.username")}
                    name="username"
                    validationSchema={validationSchema}
                    isIndependent
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputText
                    label={t("common.fullName")}
                    name="contactName"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputText
                    label={t("common.userPhone")}
                    name="contactPhone"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label={t("common.email")}
                    name="contactEmail"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikDropDown
                    label={t("common.selectRole")}
                    name="roleId"
                    validationSchema={validationSchema}
                    options={rolesOptionsQuery.data}
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikDropDown
                    label={t("common.language")}
                    name="defaultLanguage"
                    validationSchema={validationSchema}
                    options={languagesOptions}
                    disabled={!hasWriteAccess}
                    filter
                  />
                </div>

                {isBillingInfoRequired(formik) && (
                  <>
                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="Bill to"
                        name="billingInfo_BillToName"
                        validationSchema={validationSchema}
                        autoComplete="off"
                        disabled={!hasWriteAccess}
                        isRequired
                      />
                    </div>

                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="VAT EU"
                        name="billingInfo_VatEu"
                        validationSchema={validationSchema}
                        autoComplete="off"
                        disabled={!hasWriteAccess}
                        isRequired
                      />
                    </div>

                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="City"
                        name="billingInfo_City"
                        validationSchema={validationSchema}
                        autoComplete="off"
                        disabled={!hasWriteAccess}
                        isRequired
                      />
                    </div>

                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="PostalCode"
                        name="billingInfo_PostalCode"
                        validationSchema={validationSchema}
                        autoComplete="off"
                        disabled={!hasWriteAccess}
                        isRequired
                      />
                    </div>

                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="Street"
                        name="billingInfo_Street"
                        validationSchema={validationSchema}
                        autoComplete="off"
                        disabled={!hasWriteAccess}
                        isRequired
                      />
                    </div>

                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="Phone"
                        name="billingInfo_Phone"
                        validationSchema={validationSchema}
                        autoComplete="off"
                        disabled={!hasWriteAccess}
                        isRequired
                      />
                    </div>

                    <div className="field col-12 md:col-6">
                      <FormikInputText
                        label="Invoice email"
                        name="billingInfo_Email"
                        validationSchema={validationSchema}
                        autoComplete="off"
                        disabled={!hasWriteAccess}
                        isRequired
                      />
                    </div>

                    <div className="field col-12 md:col-12">
                      <FormikInputSwitch
                        label="Include pauses when pushing work time to Odoo"
                        name="includePauseTimeForInvoicing"
                        validationSchema={validationSchema}
                        isIndependent
                        disabled={!hasWriteAccess}
                        checked={formik.values.includePauseTimeForInvoicing}
                      />
                    </div>
                  </>
                )}

                <div className="field col-12 md:col-12">
                  <FormikMultiSelect
                    label={t("common.assignedInstallations")}
                    name="assignedInstallationIds"
                    validationSchema={validationSchema}
                    options={installationsOptionsQuery.data}
                    filter
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputPassword
                    label="Password"
                    name="password"
                    validationSchema={validationSchema}
                    autoComplete="off"
                    disabled={!hasWriteAccess}
                    toggleMask
                    feedback={false}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <FormikInputPassword
                    label="Repeat password"
                    name="passwordRepeat"
                    validationSchema={validationSchema}
                    disabled={!hasWriteAccess || !formik.values.password}
                    autoComplete="off"
                    toggleMask
                    feedback={false}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </LoaderWrapper>
    </div>
  );
}
