import { SelectItem } from "primereact/selectitem";
import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";

class WarehousesService extends BaseService {
  async getCountriesAsSelectOptions(): Promise<SelectItem[]> {
    const url = `${this.endpointPath}/all-as-options`;
    return this.http.get<SelectItem[]>(url).then((response) => response.data);
  }
}

export const warehousesService = new WarehousesService(API_ENDPOINTS.WAREHOUSES);
