import { useCallback, useMemo, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Language } from "../../queries/models/language.model";
import { TranslationManagerTableHeader } from "./TranslationsManagerTableHeader";
import { useUnselectSingleLanguageMutation } from "../../queries/languages.query";
import { usePublishSingleLanguageMutation } from "../../queries/languages.query";
import { useUnpublishSingleLanguageMutation } from "../../queries/languages.query";
import { confirmDialog } from "primereact/confirmdialog";
import { useQueryClient } from "react-query";
import { useToast } from "../../components/ui/toast-context-provider";
import { TranslationType } from "../../queries/models/enums/translation-type.enum";
import { EnumExtensions } from "../../utils/enum-extensions";
import { SelectItem } from "primereact/selectitem";
import { useLanguageTranslationsQuery } from "../../queries/translations.query";
import { useSetTranslationMutation } from "../../queries/translations.query";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TranslationKeyValue } from "../../queries/models/translation-key-value";
import { SetTranslationRequest } from "../../queries/models/set-translation--request.model";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

interface LanguageTranslationsManagementProps {
  hasWriteAccess?: boolean;
  selectedLanguage: Language | undefined;
  setSelectedLanguageChange: (language: Language | undefined) => void;
}

export function LanguageTranslationsManagement({
  hasWriteAccess = false,
  selectedLanguage,
  setSelectedLanguageChange,
}: LanguageTranslationsManagementProps) {
  const { t } = useTranslation();
  const [selectedTranslationKeyValue, setSelectedTranslationKeyValue] =
    useState<TranslationKeyValue | undefined>(undefined);
  const [filteredLanguageTranslations, setFilteredLanguageTranslations] =
    useState<TranslationKeyValue[]>([]);
  const [
    selectedTranslationModifiedValue,
    setSelectedTranslationModifiedValue,
  ] = useState<string | undefined>(undefined);
  const [selectedTranslationTypes, setSelectedTranslationTypes] = useState<
    SelectItem[]
  >([]);
  const translationTypesAsOptions = useMemo(
    () => EnumExtensions.getLabelAndValues(TranslationType),
    []
  );
  const toast = useToast();
  const queryClient = useQueryClient();
  const unselectSingleLanguageQuery = useUnselectSingleLanguageMutation();
  const publishSingleLanguageQuery = usePublishSingleLanguageMutation();
  const unpublishSingleLanguageQuery = useUnpublishSingleLanguageMutation();
  const languageTranslationsQuery = useLanguageTranslationsQuery(
    selectedLanguage?.id
  );
  const setTranslationMutation = useSetTranslationMutation();

  useEffect(() => {
    if (selectedTranslationTypes.length > 0) {
      const filteredData = languageTranslationsQuery.data?.filter(
        (translation) =>
          selectedTranslationTypes.some(
            (selectedType) => (selectedType as number) === translation.type
          )
      );
      setFilteredLanguageTranslations(filteredData || []);
    } else {
      setFilteredLanguageTranslations(languageTranslationsQuery.data || []);
    }
  }, [selectedTranslationTypes, languageTranslationsQuery.data]);

  useEffect(() => {
    if (selectedLanguage) {
      setSelectedTranslationTypes([]);
      setSelectedTranslationKeyValue(undefined);
    }
  }, [selectedLanguage]);

  const handleDisactiveLanguage = useCallback(() => {
    if (selectedLanguage) {
      confirmDialog({
        message: `${t("dialog.doYouWantToDisactive")} ${
          selectedLanguage?.name
        }`,
        header: t("common.confirmation"),
        icon: "pi pi-exclamation-triangle text-yellow-600",
        accept: () => {
          const mutateOptions = {
            onSuccess: async () => {
              toast.current?.show({
                severity: "success",
                detail: t("alert.disactiveSuccess"),
              });
              setSelectedLanguageChange(undefined);
              await queryClient.invalidateQueries("selected-languages");
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error?.data,
              });
            },
          };
          unselectSingleLanguageQuery.mutateAsync(
            selectedLanguage.id,
            mutateOptions
          );
        },
      });
    }
  }, [
    queryClient,
    selectedLanguage,
    setSelectedLanguageChange,
    t,
    toast,
    unselectSingleLanguageQuery,
  ]);

  const handlePublishSingleLanguage = useCallback(() => {
    if (selectedLanguage) {
      confirmDialog({
        message: `${t("dialog.doYouWantToPublish")} ${selectedLanguage?.name}`,
        header: t("common.confirmation"),
        icon: "pi pi-exclamation-triangle text-yellow-600",
        accept: () => {
          const mutateOptions = {
            onSuccess: async () => {
              toast.current?.show({
                severity: "success",
                detail: t("alert.publishSuccess"),
              });
              setSelectedLanguageChange(undefined);
              await queryClient.invalidateQueries("selected-languages");
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error?.data,
              });
            },
          };
          publishSingleLanguageQuery.mutateAsync(
            selectedLanguage.id,
            mutateOptions
          );
        },
      });
    }
  }, [
    publishSingleLanguageQuery,
    queryClient,
    selectedLanguage,
    setSelectedLanguageChange,
    t,
    toast,
  ]);

  const handleUnpublishSingleLanguage = useCallback(() => {
    if (selectedLanguage) {
      confirmDialog({
        message: `${t("dialog.doYouWantToUnpublish")} ${
          selectedLanguage?.name
        }`,
        header: t("common.confirmation"),
        icon: "pi pi-exclamation-triangle text-yellow-600",
        accept: () => {
          const mutateOptions = {
            onSuccess: async () => {
              toast.current?.show({
                severity: "success",
                detail: t("alert.unpublishSuccess"),
              });
              setSelectedLanguageChange(undefined);
              await queryClient.invalidateQueries("selected-languages");
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error?.data,
              });
            },
          };
          unpublishSingleLanguageQuery.mutateAsync(
            selectedLanguage.id,
            mutateOptions
          );
        },
      });
    }
  }, [
    queryClient,
    selectedLanguage,
    setSelectedLanguageChange,
    t,
    toast,
    unpublishSingleLanguageQuery,
  ]);

  const handleSaveNewTranslationValue = useCallback(() => {
    if (selectedLanguage && selectedTranslationModifiedValue) {
      const setTranslationRequest: SetTranslationRequest = {
        languageId: selectedLanguage?.id,
        translationId: selectedTranslationKeyValue?.translationId ?? 0,
        value: selectedTranslationModifiedValue,
      };

      confirmDialog({
        message: (
          <>
            <span>{`${t("dialog.doYouWantToChange")} `}</span>
            <span className="font-bold">
              {selectedTranslationKeyValue?.value}
            </span>
            <span>{` ${t("dialog.to")} `}</span>
            <span className="font-bold">
              {selectedTranslationModifiedValue}
            </span>
            <span>{"?"}</span>
          </>
        ),
        header: t("common.confirmation"),
        icon: "pi pi-exclamation-triangle text-yellow-600",
        accept: () => {
          const mutateOptions = {
            onSuccess: async () => {
              toast.current?.show({
                severity: "success",
                detail: t("alert.changeSuccess"),
              });
              setSelectedTranslationKeyValue(undefined);
              setSelectedTranslationModifiedValue(undefined);
              await queryClient.invalidateQueries([
                "translation-key-value",
                selectedLanguage.id,
              ]);
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error?.data,
              });
            },
          };
          setTranslationMutation.mutateAsync(
            setTranslationRequest,
            mutateOptions
          );
        },
      });
    }
  }, [
    queryClient,
    selectedLanguage,
    selectedTranslationKeyValue?.translationId,
    selectedTranslationKeyValue?.value,
    selectedTranslationModifiedValue,
    setTranslationMutation,
    t,
    toast,
  ]);

  const handleSavePreviousTranslationValue = useCallback(() => {
    if (selectedLanguage && selectedTranslationKeyValue) {
      const setTranslationRequest: SetTranslationRequest = {
        languageId: selectedLanguage.id,
        translationId: selectedTranslationKeyValue.translationId,
        value: selectedTranslationKeyValue.previousValue,
      };

      confirmDialog({
        message: (
          <>
            <span>{`${t("dialog.doYouWantToChange")} `}</span>
            <span className="font-bold">
              {selectedTranslationKeyValue?.value}
            </span>
            <span>{` ${t("dialog.to")} `}</span>
            <span className="font-bold">
              {selectedTranslationKeyValue.previousValue}
            </span>
            <span>{"?"}</span>
          </>
        ),
        header: t("common.confirmation"),
        icon: "pi pi-exclamation-triangle text-yellow-600",
        accept: () => {
          const mutateOptions = {
            onSuccess: async () => {
              toast.current?.show({
                severity: "success",
                detail: t("alert.changeSuccess"),
              });
              setSelectedTranslationKeyValue(undefined);
              setSelectedTranslationModifiedValue(undefined);
              await queryClient.invalidateQueries([
                "translation-key-value",
                selectedLanguage.id,
              ]);
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error?.data,
              });
            },
          };
          setTranslationMutation.mutateAsync(
            setTranslationRequest,
            mutateOptions
          );
        },
      });
    }
  }, [
    queryClient,
    selectedLanguage,
    selectedTranslationKeyValue,
    setTranslationMutation,
    t,
    toast,
  ]);

  const translationManagementTableHeader = useCallback(() => {
    return (
      <TranslationManagerTableHeader
        languageName={selectedLanguage?.name ?? ""}
        onSelectedLanguageChange={setSelectedLanguageChange}
        isSelectedLanguagePublished={selectedLanguage?.isPublished ?? false}
        translationTypesOptions={translationTypesAsOptions}
        selectedTranslationTypesOptions={selectedTranslationTypes}
        onSelectedTranslationTypesOptionsChange={setSelectedTranslationTypes}
        onDisactiveLanguage={handleDisactiveLanguage}
        onPublishLanguage={handlePublishSingleLanguage}
        onUnPublishLanguage={handleUnpublishSingleLanguage}
        disactiveButtonDisable={!hasWriteAccess}
        publishButtonDisable={!hasWriteAccess}
      />
    );
  }, [
    selectedLanguage?.name,
    selectedLanguage?.isPublished,
    setSelectedLanguageChange,
    translationTypesAsOptions,
    selectedTranslationTypes,
    handleDisactiveLanguage,
    handlePublishSingleLanguage,
    handleUnpublishSingleLanguage,
    hasWriteAccess,
  ]);

  return (
    <>
      {selectedLanguage ? (
        <div className="relative h-full w-full">
          <LoaderWrapper isLoading={languageTranslationsQuery.isLoading}>
            <DataTable
              key="id"
              header={translationManagementTableHeader}
              value={filteredLanguageTranslations}
              selection={selectedTranslationKeyValue}
              onSelectionChange={(e) => {
                setSelectedTranslationModifiedValue(
                  (e.value as TranslationKeyValue).value
                );
                setSelectedTranslationKeyValue(e.value as TranslationKeyValue);
              }}
              selectionMode="single"
              filterDisplay="row"
              scrollable={true}
              scrollHeight="flex"
              editMode="cell"
            >
              <Column
                header={t("common.key")}
                field="key"
                filter
                filterMatchMode="contains"
                showFilterMenu={false}
                sortable
              />
              <Column
                header={t("common.previousValue")}
                field="previousValue"
                filter
                filterMatchMode="contains"
                showFilterMenu={false}
                sortable
              />

              <Column
                header={t("common.value")}
                field="value"
                key="value"
                filter
                filterMatchMode="contains"
                sortable
                body={(options) =>
                  selectedTranslationKeyValue?.translationId !==
                  options.translationId ? (
                    <InputText
                      value={options.value ?? ""}
                      disabled={true}
                    />
                  ) : (
                    <InputText
                      value={selectedTranslationModifiedValue}
                      onChange={(e) =>
                        setSelectedTranslationModifiedValue(e.target.value)
                      }
                      disabled={!hasWriteAccess}
                    />
                  )
                }
              />
              <Column
                body={(x) =>
                  selectedTranslationKeyValue?.translationId ===
                  x.translationId ? (
                    <div className="flex gap-1">
                      <Button
                        icon="pi pi-save"
                        tooltip={t("common.saveNewValue")}
                        tooltipOptions={{ position: "bottom" }}
                        disabled={
                          selectedTranslationModifiedValue ===
                          selectedTranslationKeyValue?.value
                        }
                        onClick={handleSaveNewTranslationValue}
                      />
                      <Button
                        icon="pi pi-replay"
                        tooltip={t("common.setPreviousValue")}
                        disabled={!selectedTranslationKeyValue?.previousValue}
                        tooltipOptions={{ position: "bottom" }}
                        onClick={handleSavePreviousTranslationValue}
                      />
                      <Button
                        label={t("common.cancel")}
                        className="yellow-button"
                        onClick={() => {
                          setSelectedTranslationKeyValue(undefined);
                          setSelectedTranslationModifiedValue(undefined);
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )
                }
              />
            </DataTable>
          </LoaderWrapper>
        </div>
      ) : (
        <div className="h-full flex align-items-center justify-content-center">
          <FontAwesomeIcon
            icon={faLanguage}
            style={{ fontSize: 270 }}
            color="#7C4DFF"
          />
        </div>
      )}
    </>
  );
}
