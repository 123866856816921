import { useMemo, useCallback } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TableHeader } from "../../components/ui/table-header";
import { Company } from "../../queries/models/company-model";
import { format } from "date-fns";
import { SelectItem } from "primereact/selectitem";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

interface Props {
  items: Company[];
  onAddNew: () => void;
  selectedCompany: Company | undefined;
  onSelectCompany: (e: Company) => void;
  hasWriteAccess?: boolean;
}

export function CompaniesTableOverview({
  items,
  onAddNew,
  selectedCompany,
  onSelectCompany,
  hasWriteAccess = false,
}: Props) {
  const { t } = useTranslation();

  const isActiveOptions: SelectItem[] = useMemo(
    () => [
      {
        value: true,
        label: t("common.active"),
      },
      {
        value: false,
        label: t("common.inactive"),
      },
    ],
    [t]
  );

  const isActiveFilterElement = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <Dropdown
        options={isActiveOptions}
        placeholder={t("common.any")}
        value={options.value}
        showClear
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          options.filterApplyCallback(e.value, options.index);
        }}
      />
    ),
    [isActiveOptions, t]
  );

  const isActiveBodyTemplate = useCallback(
    (isActive: boolean) =>
      isActive ? t("common.active") : t("common.inactive"),
    [t]
  );

  return (
    <div className="h-full border-round-xl">
      <DataTable
        className="h-full"
        scrollable={true}
        scrollHeight="flex"
        value={items}
        filterDisplay="row"
        selectionMode="single"
        selection={selectedCompany}
        onSelectionChange={(e) => onSelectCompany(e.value as Company)}
        header={
          <TableHeader
            header={t("common.companies")}
            showButton
            onClick={onAddNew}
          />
        }
      >
        <Column
          field="name"
          header={t("common.name")}
          sortable
          filter
          filterMatchMode="contains"
          showFilterMenu={false}
          filterPlaceholder={t("common.filter")}
          className="w-3"
        />
        <Column
          field="email"
          header={t("common.email")}
          sortable
          filter
          filterMatchMode="contains"
          showFilterMenu={false}
          filterPlaceholder={t("common.filter")}
          className="w-3"
        />
        <Column
          field="address"
          header={t("common.address")}
          style={{
            maxWidth: "200px",
            overflow: "clip",
            textOverflow: "ellipsis",
          }}
          sortable
          filter
          filterMatchMode="contains"
          showFilterMenu={false}
          filterPlaceholder={t("common.filter")}
          className="w-4"
        />
        <Column
          field="isActive"
          header={t("common.isActive")}
          filter
          showFilterMenu={false}
          filterElement={isActiveFilterElement}
          filterMatchMode="equals"
          sortable
          body={(x) => isActiveBodyTemplate(x.isActive)}
        />
        <Column
          dataType="date"
          field="dateCreated"
          header={t("common.dateCreated")}
          body={(x) => format(x.dateCreated ?? 0, "dd/LL/yyyy")}
          sortable
        />
        <Column
          dataType="date"
          field="dateOfExpire"
          header={t("common.expire")}
          body={(x) =>
            x.dateOfExpire ? format(x.dateOfExpire, "dd/LL/yyyy") : "Never"
          }
          sortable
        />
      </DataTable>
    </div>
  );
}
